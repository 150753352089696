// export const baseUrl = "https://belizebankapiqa.azurewebsites.net"
// export const baseUrl = "http://[::1]:3002"
// export const baseUrl = 'https://bblolatp.belizebank.com'
export const baseUrl = 'https://bblonlineloans.belizebank.com' // ! SECURE DEV ENV
// export const baseUrl = 'http://192.168.16.36:3000'
// export const baseUrl = 'http://190.110.54.245:8092'
// export const baseUrl = 'https://bblolatp2.belizebank.com' // ! DEV
// export const baseUrl = 'http://192.168.16.36:3004'    // ! DEV_LOCAL


/*
  root /usr/share/nginx/customer1.bblbze.com/html;
*/
