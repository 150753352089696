import { Tooltip, Typography } from '@material-ui/core'
import TooltipIcon from '../../../../assets/Images/icon-blue.png'
import { useState } from 'react'
import { useStyles } from './TitleWithTooltipStyle'

const TitleWithTooltip = ({Text, tooltipMessage, color, margin, fontSize = '14px', fontWeight = '700', marginBottom = '0px', aling= 'center'}) => {
  const [showTooltip, setShowTooltip] = useState(false) // Estado para mostrar u ocultar el Tooltip
  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }
  const classes = useStyles()

     return (
        <Typography 
        style={
         {color: color, 
         fontSize: fontSize, 
         fontWeight: fontWeight, 
         textAlign: aling, 
         marginTop: margin, 
         marginBottom: marginBottom
         }}>
          {Text}

          <Tooltip 
                    open={showTooltip}
                    title={tooltipMessage} 
                    placement='bottom-end'
                    classes={{ tooltip: classes.tooltip }} // Clase personalizada para el tooltip
                    PopperProps={{
                      disablePortal: false // Evitar problemas de posicionamiento
                    }}
          >
            <span
              style={{position: 'relative'}}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={(e) => {
                e.stopPropagation()
                toggleTooltip()
              }}
            >
              <img
                className='info-icon'
                src={TooltipIcon}
                style={{ cursor: 'pointer', width: '16px', position: 'absolute', top: '0px', left: '2px' }}
              />
            </span>
          </Tooltip>
          
          </Typography>
     )
   }
   
   export default TitleWithTooltip
   