import React, { Fragment, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { useStyles } from './MonthlyCreditObligationsStyle'
import MonthlyCreditData from './Monthly'
import Total from '../../Inputs/Total2'
import Radio from '@mui/material/Radio'
import { Stack } from '@mui/material'
import { InputLabel, Typography } from '@material-ui/core'
import ArrowButton from '../../ArrowButton/ArrowButton'
import { FormikProvider, useFormik, Field } from 'formik'
import { validationSchema, template } from './validation'
import { Interceptor } from '../../../Utils/Interceptor'
import { defaultValues, getData } from './getRequest'
import Actions from '../../../Store/Types'
import { useDispatch, useSelector } from 'react-redux'
import { useRef } from 'react'
import axios from 'axios'
import Currency from '../../Inputs/currency2'
import { baseUrl } from '../../../baseUrl'

const MonthlyCreditObligations = () => {
  const classes = useStyles()
  const [total, setTotal] = useState(0)
  const [isValid, setIsValid] = useState(true)
  const [isChecked, setChecked] = useState(false)
  const [isRequired, setRequired] = useState(false)
  const [initialFormValues, setInitialFormValues] = useState(defaultValues)
  const [radioButtonError, setRadioButtonError] = useState('')
  const AxiosInterceptor = Interceptor()
  const [currentObligations, setCurrentObligations] = useState({})
  const dispatch = useDispatch()
  const countPage = useRef(0)
  const { steepLinks } = useSelector((state) => state.steeps)

  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  const evaluate = (obj = []) => {
    const isComplete = []
    obj.map((monthly) => {
      const { amount, bankId, file } = monthly
      if (Number(amount) <= 0 && !bankId && !file) isComplete.push(true)
      else if (amount && bankId) isComplete.push(true)
      // else if (formik.values.other && amount && !bankId) isComplete.push(true)
      // else if (amount && file && !bankId) isComplete.push(true)
      else isComplete.push(false)
    })
    return isComplete.includes(false)
  }

  useEffect(() => {
    const config = {
      token: sessionStorage.getItem('code')
    }
    async function fetchData() {
      await AxiosInterceptor.post(
        `${baseUrl}/customer/bbank/credit-obligations`,
        config
      ).then((response) => {
        setCurrentObligations({ ...response.data.creditObligations })
        // console.log(response.data.creditObligations)
      })
    }
    fetchData()
  }, [])

  const formik = useFormik({
    enableReintialize: true,
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
    }
  })
  useEffect(() => {
    let amount = 0
    formik.values.loanPayments.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    formik.values.morageOrRent.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    formik.values.creditCard.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    formik.values.overDraft.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    formik.values.other.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    formik.values.rent.map((payment, index) => {
      if (payment.amount != '')
        amount =
          amount + parseFloat(payment.amount?.toString().replace(/,/g, ''))
    })
    // console.log(amount, 'AMOUNT')
    setTotal(Number(amount).toFixed(2))
  }, [formik.values])

  useEffect(() => {
    if (!isChecked) setIsValid(false)
    else {
      setRadioButtonError('')
      setIsValid(true)
    }
  }, [total, isChecked])

  useEffect(() => {
    const isFullfield = []
    // console.log('LoanPayments TIENES FALSE', evaluate(formik.values.loanPayments))
    // console.log('morageOrRent TIENES FALSE', evaluate(formik.values.morageOrRent))
    // console.log('creditCard TIENES FALSE', evaluate(formik.values.creditCard))
    // console.log('overDraft TIENES FALSE', evaluate(formik.values.overDraft))
    // console.log('other TIENES FALSE', evaluate(formik.values.other))

    if (
      evaluate(formik.values.loanPayments) ||
      evaluate(formik.values.morageOrRent) ||
      evaluate(formik.values.creditCard) ||
      evaluate(formik.values.overDraft) 
      // evaluate(formik.values.other)
    ) {
      isFullfield.push(true)
    } else isFullfield.push(false)

    if (isFullfield.includes(false)) setRequired(false)
    else setRequired(true)
  }, [formik.values])

  const handleSubmit = () => {
    if (!isRequired && isChecked && formik.isValid) {
      setRadioButtonError('')
      mapLoanPayments()
      mapMorageOrRent()
      mapcreditCard()
      mapoverDraft()
      mapOther()
      mapRent()
    } else {
      if (!isChecked) setRadioButtonError('This field is mandatory ')
    }
  }

  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve('')
      })
    }
  }

  const mapLoanPayments = async () => {
    const formArray = await Promise.all(
      formik.values.loanPayments.map(async (payment, index) => {
        const { amount, bankId, file, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          bankId: bankId.id ? bankId.id : null,
          statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Loan Payments',
          statementFile: await convertBase64(file),
          id: id
        }
        return data
      })
    )

    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post(
            '/monthly-credit-obligations/Loan Payments',
            array
          )
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  const mapMorageOrRent = async () => {
    const formArray = await Promise.all(
      formik.values.morageOrRent.map(async (payment, index) => {
        const { amount, bankId, file, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          bankId: bankId.id ? bankId.id : null,
          statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Mortgage or rent',
          statementFile: await convertBase64(file),
          id: id
        }
        return data
      })
    )
    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post(
            '/monthly-credit-obligations/Mortgage or rent',
            array
          )
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  const mapcreditCard = async () => {
    const formArray = await Promise.all(
      formik.values.creditCard.map(async (payment, index) => {
        const { amount, bankId, file, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          bankId: bankId.id ? bankId.id : null,
          statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Credit Card',
          statementFile: await convertBase64(file),
          id: id
        }
        return data
      })
    )
    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post(
            '/monthly-credit-obligations/Credit Card',
            array
          )
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  const mapoverDraft = async () => {
    const formArray = await Promise.all(
      formik.values.overDraft.map(async (payment, index) => {
        const { amount, bankId, file, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          bankId: bankId.id ? bankId.id : null,
          statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Overdraft',
          statementFile: await convertBase64(file),
          id: id
        }
        return data
      })
    )
    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post(
            '/monthly-credit-obligations/Overdraft',
            array
          )
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  const mapOther = async () => {
    const formArray = await Promise.all(
      formik.values.other.map(async (payment, index) => {
        const { amount, bankId, file, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          bankId: bankId.id ? bankId.id : null,
          statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Other',
          statementFile: await convertBase64(file),
          id: id
        }
        return data
      })
    )
    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post(
            '/monthly-credit-obligations/Other',
            array
          )
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  const mapRent = async () => {
    const formArray = await Promise.all(
      formik.values.rent.map(async (payment, index) => {
        const { amount, id, customerId } = payment
        let data = {
          amount: amount?.toString().replace(/,/g, ''),
          // bankId: bankId.id ? bankId.id : null,
          // statementFileName: file ? file.name : null,
          customerId: customerId,
          category: 'Rent',
          // statementFile: await convertBase64(file) || null,
          id: id
        }
        return data
      })
    )
    formArray.map(async (payment, index) => {
      if (payment.id == '') {
        if (
          payment.amount != '0' &&
          payment.amount != '' &&
          payment.amount != 0
        ) {
          delete payment.id
          delete payment.customerId
          delete payment.category
          let array = [payment]
          await AxiosInterceptor.post('/monthly-credit-obligations/Rent', array)
        }
      } else {
        await AxiosInterceptor.patch(
          `/monthly-credit-obligations/${payment.id}`,
          payment
        )
      }
    })
  }
  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    getData()
      .then((values) => {
        const data = {
          loanPayments: values[0],
          morageOrRent: values[1],
          creditCard: values[2],
          overDraft: values[3],
          other: values[4],
          rent: values[5]
        }
        formik.setValues({ ...data })
      })
      .finally(() => {
        dispatch({ type: Actions.LOADING, payload: false })
      })
  }, [])
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  return (
    <Box className={classes.MonthlyCreditContainer}>
      <Box className={classes.MonthlyCreditHeader}>
        <Typography>
          PLEASE COMPLETE THE INFORMATION BELOW WITH YOUR MONTHLY CREDIT
          OBLIGATIONS
        </Typography>
      </Box>
      <Fragment>
        <FormikProvider value={formik}>
          <Stack mt={4}>
            <Typography className={classes.LoanWithUs}>
              {(currentObligations.cardTotal !== 0 ||
                currentObligations.mortgageTotal !== 0 ||
                currentObligations.overdraftTotal !== 0 ||
                currentObligations.otherLnTotal !== 0) && (
                <>LOANS AND OTHER CREDIT OBLIGATIONS WITH US</>
              )}
            </Typography>

            {currentObligations.cardTotal != 0 && (
              <Currency
                className={classes.MonthlyCreditInput}
                value={currentObligations.cardTotal}
                readOnly
              />
            )}
            {currentObligations.mortgageTotal != 0 && (
              <Currency
                className={classes.MonthlyCreditInput}
                value={currentObligations.mortgageTotal}
                readOnly
              />
            )}
            {currentObligations.overdraftTotal != 0 && (
              <Currency
                className={classes.MonthlyCreditInput}
                value={currentObligations.overdraftTotal}
                readOnly
              />
            )}
            {currentObligations.otherLnTotal != 0 && (
              <Currency
                className={classes.MonthlyCreditInput}
                value={currentObligations.otherLnTotal}
                readOnly
              />
            )}
          </Stack>
          <MonthlyCreditData formik={formik} />

          <Box className={classes.Total}>
            <Total
              total={
                parseFloat(total) +
                  currentObligations.cardTotal +
                  currentObligations.mortgageTotal +
                  currentObligations.otherLnTotal +
                  currentObligations.overdraftTotal || 0
              }
            />
          </Box>
          <Stack
            className={classes.RadioBtn}
            direction='row'
            alignItems='center'
            mt={1}
          >
            <Radio checked={isChecked} onClick={() => setChecked(!isChecked)} />

            <InputLabel className={classes.RadioLabel}>
              I confirm that all the information provided above is true and
              accurate.
            </InputLabel>
          </Stack>
          <Stack className={classes.otherErrorMessage}>
            <Typography>{radioButtonError}</Typography>
          </Stack>
        </FormikProvider>
        <ArrowButton
          disabled={isRequired}
          msg='You cannot leave empty fields. [financial institution, amount] is required.'
          link={
            isValid
              ? (sessionStorage.getItem('loan') === 'secure' &&
                  sessionStorage.getItem('warrantyTypeID') === '1') ||
                sessionStorage.getItem('loan') === 'refinancing'
                ? '/Customer/LoanPurpose'
                : (sessionStorage.getItem('loan') === 'secure' &&
                  ['2', '3'].includes(sessionStorage.getItem('warrantyTypeID')))
                ? '/Customer/ProofOfIncome'
                : sessionStorage.getItem('loan') === 'unsecure' ? '/Customer/LoanPurpose' : '#'
              : ''
          }
          back={'/Customer/monthlyIncome'}
          onClick={() => {
            AxiosInterceptor.post('/customers/step', { currentStep: 5 })
              .then((response) => {
                console.log(response)
              })
              .catch((e) => console.log(e))
            formik.submitForm()
          }}
        />
      </Fragment>
    </Box>
  )
}

export default MonthlyCreditObligations
