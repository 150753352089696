import React from 'react'
import { Box } from '@mui/material'
// import ArrowButton from '../../ArrowButton/ArrowButton'
import { useStyles } from './ScheduleAppointmentStyle3'
import Approved from '../../../assets/Images/Group 590.png'
// import Calendar from '../../../assets/Images/Calendar.png'
import { Typography } from '@material-ui/core'
// import Button from '../../Button/Button'
// import Select from '../../Inputs/Select'
// import AttachFileIcon from '@mui/icons-material/AttachFile'
// import FileDownloadIcon from '@mui/icons-material/FileDownload'
// import DatePicker from '../../Inputs/DatePicker'
// import TimePicker from '../../Inputs/TimePicker'
// import clsx from 'clsx'

const ScheduleApproved = () => {
  const classes = useStyles()
  // const inputFile = useRef(null)

  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Approved} alt='' />
          </Box>
          <Typography className={classes.ApprovedText}>
            {sessionStorage.getItem('loan') === 'secure' &&
            sessionStorage.getItem('warrantyType') === 'vehicle' ? (
              <span>
                <strong>SIGNING OF LOAN DOCUMENTS APPOINTMENT</strong>
                <br /> Please set your appointment when the{' '}
                <strong>TRANSFER OF TITLE</strong> is completed. Kindly bring
                the newly issued vehicle title on the scheduled date.
              </span>
            ) : sessionStorage.getItem('loan') === 'secure' &&
            sessionStorage.getItem('warrantyType') === 'land' ? (
              <span>
                <strong>As your approved facility is secured by land</strong> <br />
                please set up an appointment below to sign all the required documents..
              </span>
            ) : (
              sessionStorage.getItem('loan') === 'refinancing' ? <span>
                <strong>Your approved loan facility requires your signature prior to disbursement.</strong> <br /> You can conveniently schedule an appointment below to visit the branch nearest to you.
              </span> : <span>
              <strong>As your approved loan facility is above BZ$7,500,</strong> <br />
              please setup an appointment with the nearest branch in order to sign all necessary documents.
            </span>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ScheduleApproved
