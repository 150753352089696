import React, { useEffect, useState } from 'react'
import { Typography, styled } from '@mui/material'
import { LoanAmountDetails } from './LoanAmountDetails'
import { LoanTermsDetails, LoanTermsDetailsFully } from './LoanTermDetails'
import { useStyles } from './LoanDetailsStyle'
import { Stack, Box } from '@mui/system'
import Input from '../Inputs/Input'
import clsx from 'clsx'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import LoanAmountTooltip from '../Tooltips/LoanAmountTooltip/LoanAmountTooltip'
import { useSelector } from 'react-redux'
import { FormikProvider, useFormik, Field } from 'formik'
import { InputAdornment } from '@mui/material'
import Currency from '../Inputs/currency'
import InputWithAdornment from '../Inputs/InputWithAdornment'
import * as yup from 'yup'

import './styles.css'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'BZD',
})

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    maxWidth: 550,
    filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    '&::before': {
      backgroundColor: 'white',
    },
  },
}))

const LoanDetails = ({ setAmount, amount, setTerms, terms, error, errorTerms, typeLoan, typeOfCollateral }) => {
  const loand_request = useSelector((state) => state.LoanRequestReducer)
  const {
    requestedAmount,
    requestedTerms,
    debtCancellationSchemeRequested,
    debtCancellationSchemeFee,
    fees,
    totalAmount,
    monthlyInstallment,
    promoCode,
    monthlyInstallmentWithPromoCode,
    createdDate,
    interestRate,
    interestRateWithPromoCode,
    customerId,
    amount: monto,
    terms: terminos,
  } = useSelector((state) => state.LoanRequestReducer)

  const classes = useStyles()
  const [activeId, setActiveId] = useState()
  const [activeLoanTerms, setActiveLoanTerms] = useState()
  const formik = useFormik({
    initialValues: {
      other1: '',
      other2: '',
    },
  })

  useEffect(() => {
    if (monto > 0) {
      const amt = LoanAmountDetails.find((o) => o.value === monto)
      if (amt) setActiveId(amt.id)
      else {
        setActiveId(8)
        formik.setFieldValue('other1', monto)
      }
    }
  }, [monto])

  useEffect(() => {
    if (terminos > 0) {
      let tems
      if(typeOfCollateral === "partially") tems = LoanTermsDetails.find((o) => o.value === terminos)
      if(typeOfCollateral === "fully") tems = LoanTermsDetailsFully.find((o) => o.value === terminos)
      if (typeOfCollateral === undefined) tems = LoanTermsDetails.find((o) => o.value === terminos)
      if (tems) setActiveLoanTerms(tems.id)
      else if (typeLoan === "SECURE" && typeOfCollateral === "partially" && terms > 60) {
        setActiveLoanTerms(8)
        formik.setFieldValue('other2', '')
      } else if (typeLoan === "SECURE" && typeOfCollateral === "fully" && terms > 120) {
        setActiveLoanTerms(8)
        formik.setFieldValue('other2', '')
      } else {
        setActiveLoanTerms(8)
        formik.setFieldValue('other2', terminos)
      }
    }
  }, [terminos])

  const [open, setOpen] = React.useState(false)

  return (
    <>
      <FormikProvider value={formik}>
        <Stack direction="column" className={[
          classes.Input
          ]}>
          <Typography className={[
              sessionStorage.getItem('loan') !== 'secure' ? classes.InputTitle : classes.InputTitleSecured
            ]}>LOAN AMOUNT</Typography>
          <Input
            className={classes.BlankInput}
            type="text"
            value={`${
              (amount ? formatter.format(amount) : amount) || monto.amount || 'BZD'
            }`}
            placeholder={`${amount || monto.amount || ''} `}
            readOnly
            disabled={true}
          />
          {typeLoan !== 'SECURE' && LoanAmountDetails.map((amount) => (
            <LightTooltip
              title={amount.value === 7500 ? <LoanAmountTooltip /> : ''}
              arrow
              placement="bottom"
              key={amount.id}
              open={open}
              onClick={() => setOpen(!open)}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={[
                  classes.InputBox,
                  activeId === amount.id ? 'active' : '',
                ]}
                onClick={() => {
                  setAmount(amount.value)
                  setActiveId(amount.id)
                }}
              >
                <Typography>{amount.amount}</Typography>
              </Stack>
            </LightTooltip>
          ))}
          {typeLoan !== 'SECURE' &&
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={[
                clsx(classes.InputBox, classes.OtherButton),
                activeId === 8 ? 'active' : '',
              ]}
              onClick={() => {
                setActiveId(8)
              }}
          >
            <Typography>OTHER</Typography>
          </Stack>
          }

          {(activeId === 8 && typeLoan !== 'SECURE') && (
            <Box>
              <Field name="other1">
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                      meta.value !== '' &&
                      setAmount(meta.value)}
                    <Currency
                      error={formik.touched.other1 ? error : ''}
                      value={meta.value}
                      onChange={(event, value) => {
                        formik.setFieldValue(field.name, value)
                        formik.setFieldTouched(field.name)
                        setAmount(parseFloat(value) ? parseFloat(value) : '')
                      }}
                    />
                  </>
                )}
              </Field>
            </Box>
          )}
        </Stack>

        <Stack direction="column" className={classes.Input}>
          <Typography className={classes.InputTitle}>LOAN TERM <small>
              {sessionStorage.getItem('loan') === 'secure' && typeOfCollateral === 'fully' ? '(Select one value or select OTHER and enter the desired term up to a maximum of 120 months)' : sessionStorage.getItem('loan') === 'secure' && typeOfCollateral === 'partially' ? '(The term for partially secured loans must not exceed 60 months. Please select your preferred option.)' : '(Select one)'}
            </small>
          </Typography>
          <Input
            className={classes.BlankInput}
            type="text"
            value={`${terms || terminos.valueTerm || ''} months`}
            placeholder={`${terms || terminos.terms || ''}`}
            // disabled
            readOnly
            disabled={true}
          />
          {typeOfCollateral !== "fully" ? LoanTermsDetails.map((term) => (
            <Stack
              key={term.id}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={[
                classes.InputBox,
                activeLoanTerms === term.id ? 'active' : '',
              ]}
              onClick={() => {
                setTerms(term.value)
                setActiveLoanTerms(term.id)
              }}
            >
              <Typography>{term.terms}</Typography>
            </Stack>
          ))
          :
          LoanTermsDetailsFully.map((term) => (
            <Stack
              key={term.id}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={[
                classes.InputBox,
                activeLoanTerms === term.id ? 'active' : '',
              ]}
              onClick={() => {
                setTerms(term.value)
                setActiveLoanTerms(term.id)
              }}
            >
              <Typography>{term.terms}</Typography>
            </Stack>
          ))
        }
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={[
              clsx(classes.InputBox, classes.OtherButton),
              activeLoanTerms === 8 ? 'active' : '',
            ]}
            onClick={() => {
              setTerms('')
              setActiveLoanTerms(8)
            }}
          >
            <Typography>OTHER</Typography>
          </Stack>
          {activeLoanTerms === 8 && (
            <Box>
              <Field name="other2">
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                      meta.value !== '' &&
                      setTerms(meta.value)}
                    <Input
                      // className={classes.BlankInput}
                      type="number"
                      error={errorTerms}
                      inputProps={{ min: 12, step: 1 }}
                      value={meta.value}
                      onChange={(e) => {
                        let v = e.target.value ? parseInt(e.target.value) : ''
                        formik.setFieldValue(field.name, v)
                        setTerms(v)
                      }}
                      style={{ paddingBottom: 17 }}
                    />
                  </>
                )}
              </Field>
            </Box>
          )}
        </Stack>
      </FormikProvider>
    </>
  )
}

export default LoanDetails
