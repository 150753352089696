import React, { useEffect, useRef, useState } from 'react'
import { Stack, Container } from '@mui/system'
import { Card } from '@mui/material'
import { Divider } from '@mui/material'
import { Typography } from '@material-ui/core'
import CardContent from '@mui/material/CardContent'
import Button from '../../Components/Button/Button'
import { useStyles } from './HomeStyle'
import Box from '@mui/material/Box'
import { Link, useLocation } from 'react-router-dom'
import OFFER_IMAGE from '../../assets/Images/home/OLA-sizes-01.png'
import { useNavigate } from 'react-router-dom'
import setToken from '../../hooks/useToken'
import { Interceptor } from '../../Utils/Interceptor'
import { baseUrl } from '../../baseUrl'
import ScreenLoading from './HomeLoading'
import { useSelector, useDispatch } from 'react-redux'
import { hasActiveLoan } from '../initial-loan-process/context'
import { ReactComponent as ReactArrow } from '../../assets/Images/Flecha blanca.svg'
import StartLoanImage from '../../assets/Images/start-loan.png'
import HistoryLoanImage from '../../assets/Images/loanhistory.png'
import FlechaBlanca from '../../assets/Images/FlechaBlanca'
import { setCode } from '../../Store/context/white-list'
import { initGlobalState } from '../../Utils/InitState'

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { customerLoans, pending } = useSelector(
    (state) => state.initialLoanProcess
  )
  const dispatch = useDispatch()

  sessionStorage.removeItem('code')
  const url = location.search.split('?q=')[1]
  sessionStorage.setItem('code', url)
  localStorage.setItem('code', url)
  const [statusCode, setStatus] = useState(101)
  const countPage = useRef(0)
  const [loading, setLoading] = useState(false)
  const AxiosInterceptor = Interceptor()

  const StatusValid = (arr, status) => arr.includes(status)
  const getStatusReques = () => {
    AxiosInterceptor.post(`/customer/bbank/loan-requests-history`, {
      token: url
    })
      .then((response) => {
        const statusSecure = response?.data.secure.current[0]?.status
        const statusUnsecure = response?.data.unsecure.current[0]?.status
        // console.log('status: ', status)

        if (statusSecure === 0 || statusUnsecure === 0) return

        if (
          statusSecure === null ||
          typeof statusSecure === 'undefined' ||
          statusUnsecure === null ||
          typeof statusUnsecure === 'undefined'
        ) {
          //navigate('not-found')
          setStatus(-1) // is undefined or null change 100 defaultvalue
          // console.log('status undefined: ', statusCode)
          return
        }
        setStatus(statusSecure || statusUnsecure)
        // console.log('status: ', statusCode)
      })
      .catch(() => {
        sessionStorage.clear()
        navigate('not-found')
      })
  }

  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current++
      setTimeout(() => {
        setToken(`${baseUrl}/customer/bbank`, url, dispatch)
        .then((haveToken) => {
          if (!haveToken) return navigate('not-found')
            setLoading(haveToken)
          dispatch(hasActiveLoan())
            // getStatusReques()
          })
          .catch(() => navigate('not-found'))
      }, 2_500)
      }
    }, [])

    useEffect(() => {
      dispatch(setCode(url))
      // if (token) dispatch(hasActiveLoan())
  }, [url])

  const classes = useStyles()

  useEffect(() => {
    initGlobalState(dispatch)
  }, [])
  return (
    <>
      {loading ? (
        <Container>
          {/* <Card variant="outlined" className={classes.Card}>
            <CardContent>
              <Typography className={classes.Header}>
                Online Loan Application
              </Typography>
            </CardContent>
          </Card> */}
          <Card variant='outlined' className={classes.Card}>
            <CardContent>
              <Typography className={classes.newHeadline}>
                What would you like to do today?
              </Typography>
              <Stack
                spacing={{ xs: 4, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
                mb={3}
              >
                {!pending &&
                  (!customerLoans.hasActiveSecureLoan ||
                    !customerLoans.hasActiveUnsecureLoan ||
                    !customerLoans.hasActiveDebtRefinancingLoan) && (
                    <Link
                      to='/initial-loan-process'
                      className={classes.NewCardBtn}
                      style={{backgroundImage: `url(${StartLoanImage}) `}}
                    >
                      <p className={classes.titleCard}>
                        Start a new loan application
                      </p>
                      <p className={classes.btnVariation}>
                        <span
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem'
                          }}
                        >
                          START {'  '}
                          <ReactArrow />
                        </span>
                      </p>
                    </Link>
                  )}
                <Link to='/LoanHistory' className={classes.NewCardBtn2} style={{backgroundImage: `url(${HistoryLoanImage})`}}>
                  <p className={classes.titleCard}>
                    View your loan history
                  </p>
                  <Typography className={classes.btnVariation}>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem'
                      }}
                    >
                      VIEW {'  '}
                      {/* <ReactArrow /> */}
                      <FlechaBlanca fill={'white'}/>
                    </span>
                  </Typography>
                  {/* <Button
                    className={classes.SecondButton}
                    Title="MY LOAN HISTORY"
                    variant="subtitle1"
                    BtnVariant="contained"
                  /> */}
                </Link>
              </Stack>
              <Box>
                {/* <Link to="/CustomerChat" className={classes.Links}>
              <Button
                className={classes.SecondButton}
                Title="ONLINE LOANS CHAT"
                variant="subtitle1"
                BtnVariant="contained"
              />
            </Link> */}
              </Box>
              <Stack
                spacing={{ xs: 4, md: 2 }}
                direction={{ xs: 'column', md: 'row' }}
                mt={3}
              >
                <img
                  src={OFFER_IMAGE}
                  width={'30%'}
                  alt=''
                  className={classes.PromoImg}
                />
                <Stack direction='column'>
                  {/* <a href="#" className={classes.Links}> */}
                  <Button
                    Title='INTRODUCING THE FIRST FULLY DIGITAL ONLINE LOAN APPLICATION IN BELIZE!'
                    variant='subtitle1'
                    className={classes.Button2}
                    BtnVariant='contained'
                  />
                  {/* </a> */}
                  <Typography className={classes.Text}>
                    <b>
                      Our Belize Bank Online Loan Application is EASY, FAST and
                      CONVENIENT!
                    </b>{' '}
                    You can easily apply for a loan from anywhere at any time
                    without having to visit a branch in person. This saves you
                    time and effort.
                  </Typography>
                  <div className={classes.Text}>
                    <b>You can get your loan approval in one business day!</b>{' '}
                    Just be prepared to upload all the required documents online
                    which include:
                    <ul>
                      <li>
                        Valid Social Security Card (if you have not provided us
                        with a copy)
                      </li>
                      <li>
                        Employment Letter (issued within the last 6 months) or
                        Proof of Income
                      </li>
                      <li>
                        Bank statements (if you have loans at other
                        institutions)
                      </li>
                      <li>
                        Supporting documents evidencing the purpose of your loan
                        request.
                      </li>
                    </ul>
                  </div>
                  <Typography className={classes.Text}>
                    Bringing you convenience while safeguarding your funds!
                  </Typography>
                  <Divider className={classes.CardDivider} />
                  {/* <Typography className={classes.LearnMore}>Learn more</Typography> */}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Container>
      ) : (
        <ScreenLoading />
      )}
    </>
  )
}
export default Home
