import { Interceptor } from '../../Utils/Interceptor'
import Actions from '../Types'

const GetStatuses = (data, withFilter = 0) => {
  const endpoint = '/customer/bbank/loan-requests-history'
  const AxiosInterceptor = Interceptor();

  return async (dispatch) => {
    AxiosInterceptor.post(endpoint, data)
      .then((response) => {
        if (withFilter === 0) {
          dispatch({ type: Actions.GETSTATUSES, payload: { ...response.data, pending: false } })
          return
        }else if (withFilter === 1) {
          dispatch({ type: Actions.GETSTATUS_CURRENT, payload: { ...response.data, pending: false } })
          return
        }else if (withFilter === 2) {
          dispatch({ type: Actions.GETSTATUSES_COMPLETED, payload: { ...response.data, pending: false } })
          return
        }        
      })
      .catch()
      .finally()
  }
}

export default GetStatuses
