import Input from './Input'
import { Stack } from '@mui/system'
import Approved from '../../assets/Images/Grupo 999.svg'
import { useStyles } from './LoanHistoryStyles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { formatBZD } from '../../hooks/useFormatBZD'
import status, {warrantyType, LoanType} from '../status'
import { Download } from '@mui/icons-material'
import { Alert } from '@mui/material'
import AlertDownload from './AlertDownload'
import { useMovil } from '../../hooks/useMovil'
import AlertInfo from '../Alert'
// import Declined from '../../assets/Images/Grupo 1002.svg'

const AcordionDisbursed = ({ Title, ...loan }) => {
  const classes = useStyles()
  const { movil, message } = useMovil('loaddocuments')
  const {
    name,
    submissionDate,
    approvalDate,
    amount,
    status: indexStatus,
    term,
    disbursedDate,
    loanTypeId,
    warrantyTypeId
  } = loan?.data

  const handleClickDownload = (doc, typeDoc) => {
    const url = doc
    const link = document.createElement('a')
    link.href = url
    link.setAttribute(
      'download',
      String(name + ' -- ' + typeDoc + ' -- ' + status[indexStatus].label)
    )
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Accordion elevation={0} className={classes.AccordianInner}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction='column' alignItems='center' justifyContent='center'>
          <Typography variant='body1'>
            {/* {name === undefined ? '' : name} */}
            {loanTypeId === 1 ? `PERSONAL LOAN - ${LoanType[loanTypeId]?.name}` : loanTypeId === 3 ? `${LoanType[loanTypeId]?.name}` : `${warrantyType[warrantyTypeId]?.name} - ${LoanType[loanTypeId].name}` }
          </Typography>

          <Typography
            variant='body2'
            className={
              loan.data.status === 9 ? classes.Declined : classes.Disbursed
            }
          >
            {Title}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#FFFAF9', padding: 10 }} mt={3}>
        {loan.data.status === 4 && (
          <Stack
            justifyContent='center'
            alignItems='center'
            width='100%'
            sx={{ marginTop: '-40px' }}
          >
            <img className={classes.ApprovedImage} src={Approved} alt='' />
          </Stack>
        )}
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          spacing={2}
          mb={2}
        >
          <Input
            Lable='SUBMISSION DATE'
            name='specifyTheNatureOfOtherIncome'
            value={moment(submissionDate).format('DD-MM-YYYY')}
            disabled
            readOnly
          />
          <Input
            Lable='APPROVAL DATE'
            name='specifyTheNatureOfOtherIncome'
            value={moment(approvalDate).format('DD-MM-YYYY')}
            readOnly
            disabled
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          spacing={2}
          mb={2}
        >
          <Input
            Lable='AMOUNT'
            name='specifyTheNatureOfOtherIncome'
            value={formatBZD(amount)}
            readOnly
            disabled
          />
          <Input
            Lable='TERM'
            name='specifyTheNatureOfOtherIncome'
            value={`${term} months`}
            readOnly
            disabled
          />
        </Stack>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          spacing={2}
          mb={2}
        >
          <Input
            Lable='STATUS'
            name='specifyTheNatureOfOtherIncome'
            value={status[indexStatus].label}
            readOnly
            disabled
          />
          <Input
            Lable='DISBURSED DATE'
            name='specifyTheNatureOfOtherIncome'
            value={moment(disbursedDate).format('DD-MM-YYYY')}
            readOnly
            disabled
          />
        </Stack>
        {(loan?.data?.debtCancellationScheme ||
          loan?.data?.disclosureSummary ||
          loan?.data?.loanApplicationDeclaration ||
          loan?.data?.promissoryNote || loan?.data?.cashCollateralAgreement
          ) &&
          !movil && (
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'colum' }}
              spacing={2}
              mb={2}
            >
              <Stack sx={{ width: '100%' }} spacing={2}>
                {loan?.data?.debtCancellationScheme && (
                  <Alert
                    className={`${classes.buttonAlert}`}
                    icon={<Download fontSize='inherit' />}
                    severity='info'
                    onClick={() =>
                      handleClickDownload(
                        loan?.data?.debtCancellationScheme,
                        'debtCancellationScheme'
                      )
                    }
                  >
                    {String('debt Cancellation Scheme').toLocaleUpperCase()}
                  </Alert>
                )}
                {loan?.data?.disclosureSummary && (
                  <Alert
                    className={`${classes.buttonAlert}`}
                    icon={<Download fontSize='inherit' />}
                    severity='info'
                    onClick={() =>
                      handleClickDownload(
                        loan?.data?.disclosureSummary,
                        'disclosureSummary'
                      )
                    }
                  >
                    {String('disclosure Summary').toLocaleUpperCase()}
                  </Alert>
                )}
                {loan?.data?.loanApplicationDeclaration && (
                  <Alert
                    className={`${classes.buttonAlert}`}
                    icon={<Download fontSize='inherit' />}
                    severity='info'
                    onClick={() =>
                      handleClickDownload(
                        loan?.data?.loanApplicationDeclaration,
                        'loanApplicationDeclaration'
                      )
                    }
                  >
                    {String('loan Application Declaration').toLocaleUpperCase()}
                  </Alert>
                )}
                {loan?.data?.promissoryNote && (
                  <Alert
                    className={`${classes.buttonAlert}`}
                    icon={<Download fontSize='inherit' />}
                    severity='info'
                    onClick={() =>
                      handleClickDownload(
                        loan?.data?.promissoryNote,
                        'promissoryNote'
                      )
                    }
                  >
                    {String('promissory Note').toLocaleUpperCase()}
                  </Alert>
                )}
                {loan?.data?.cashCollateralAgreement && (
                  <Alert
                    className={`${classes.buttonAlert}`}
                    icon={<Download fontSize='inherit' />}
                    severity='info'
                    onClick={() =>
                      handleClickDownload(
                        loan?.data?.cashCollateralAgreement,
                        'cashCollateralAgreement'
                      )
                    }
                  >
                    {String('Cash Collateral Agreement').toLocaleUpperCase()}
                  </Alert>
                )}
              </Stack>
            </Stack>
          )}

        {(loan?.data?.debtCancellationScheme ||
          loan?.data?.disclosureSummary ||
          loan?.data?.loanApplicationDeclaration ||
          loan?.data?.promissoryNote || loan?.data?.cashCollateralAgreement) &&
          movil && <AlertInfo content={message} />}

        {/* // * Dispositivos que no sean movil */}
        {loan.data.bblDocs.length > 0 && amount <= 7500 && !movil && (
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'colum' }}
            spacing={2}
            mb={2}
          >
            <Stack sx={{ width: '100%' }} spacing={2}>
              {loan.data.bblDocs.map((document) => (
                <Alert
                  className={`${classes.buttonAlert}`}
                  icon={<Download fontSize='inherit' />}
                  severity='info'
                  onClick={() =>
                    handleClickDownload(document.file, document.fileName)
                  }
                >
                  {String(document.fileName).toLocaleUpperCase()}
                </Alert>
              ))}
            </Stack>
          </Stack>
        )}

        {/* // * Dispositivos movil */}
        {loan.data.bblDocs.length > 0 && amount <= 7500 && movil && (
          <AlertInfo content={message} />
        )}

        {loan.data.bblDocs.length > 0 && amount > 7500 && !movil && (
          <>
            {loan.data.bblDocs.map((file) => (
              <AlertDownload bblDoc={file} key={file.id} />
            ))}
          </>
        )}
        {loan.data.bblDocs.length > 0 && amount > 7500 && movil && ( // TODO: cambiar !movil por movil
          <AlertInfo content={message} />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default AcordionDisbursed
