import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useStyles } from './LoanApprovedStyle'
import Approved from '../../../assets/Images/Grupo 999.svg'
// import Progress from '../../ProgressBar/ProgressBar'

const ApprovedBelow7k = () => {
  const classes = useStyles()

  return (
    <Stack className={classes.container}>
    {/* <Progress progrress={100}/> */}
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Approved} alt='' />
          </Box>
          <Typography className={classes.ApprovedText}>
            <strong>Congratulations on your loan approval!</strong>
            <br />
            Before you proceed to digitally sign your loan documents, please review and acknowledge each agreement. 
            Thank you for giving us the opportunity to serve you!
          </Typography>
        </Box>
      </Box>
    </Stack>
  )
}

export default ApprovedBelow7k
