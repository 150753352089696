import personalLoanActive from "../../../../../assets/Icons/Personal-Loan-Active.png";
import personalLoanInactive from "../../../../../assets/Icons/Personal-Loan-Inactive.png";
import vehicleActive from "../../../../../assets/Icons/Vehicle-Loan-Active.png";
import vehicleInactive from "../../../../../assets/Icons/Vehicle-Loan-Inactive.png";
import landLoanActive from "../../../../../assets/Icons/Land-Loan-Active.png";
import landLoanInactive from "../../../../../assets/Icons/Land-Loan-Inactive.png";
import debtRefinancingActive from "../../../../../assets/Icons/Debt-Refinancing-Active.png";
import debtRefinancingInactive from "../../../../../assets/Icons/Debt-Refinancing-Inactive.png";
import BgPersonalLoan from '../../../../../assets/Images/Personal-loan.png';
import BgLandLoan from '../../../../../assets/Images/Land-loan.png';
import BgVehicleLoan from '../../../../../assets/Images/Vehicle-loan.png';
import BgRefinancing from '../../../../../assets/Images/Debt-ref.png';

export const LoanTypesInfo = [
    {
        ActiveImg: personalLoanActive,
        InactiveImg: personalLoanInactive,
        PrincipalTitle: "Personal Loan",
        SecondTitel: "LOAN",
        Width: "56px",
        Heigth: "56px",
        InfoIcon: true,
        loanType:"secure-unsecure",
        warrantyType: "cash",
        bgImage: BgPersonalLoan,
        gradient: '90deg, rgba(98,144,194,1) 0%, rgba(98,144,194,0.60) 45%',
        bgColor: '#0F5CAC',
        setting: {
            environment: process.env.REACT_APP_ENVIRONMENT || 'TEST',
            loanType: 'secure-unsecure',
            evaluateInternal: true
        }
    },
    {
        ActiveImg: landLoanActive,
        InactiveImg: landLoanInactive,
        PrincipalTitle: `Land Loan`,
        SecondTitel: "LOAN",
        Width: "55px",
        Heigth: "54px",
        InfoIcon: true,
        loanType:"secure",
        warrantyType: "land",
        bgImage: BgLandLoan,
        gradient: '90deg, rgba(86,175,49,1) 0%, rgba(170,238,31,0.50) 45%',
        bgColor: '#5BB133',
        setting: {
            environment: process.env.REACT_APP_ENVIRONMENT || 'TEST'
        }
    },
    {
        ActiveImg: vehicleActive,
        InactiveImg: vehicleInactive,
        PrincipalTitle: `Vehicle Loan`,
        SecondTitel: "LOAN",
        Width: "54px",
        Heigth: "54px",
        InfoIcon: true,
        loanType:"secure",
        warrantyType: "vehicle",
        bgImage: BgVehicleLoan,
        gradient: '90deg, rgba(0,88,255,1) 0%, rgba(0,88,255,0.50) 45%',
        bgColor: '#0A5DFD',
        setting: {
            environment: process.env.REACT_APP_ENVIRONMENT || 'TEST'
        }
    },
    {
        ActiveImg: debtRefinancingActive,
        InactiveImg: debtRefinancingInactive,
        PrincipalTitle: `Debt Refinancing`,
        SecondTitel: "REFINANCING",
        Width: "71.28px",
        Heigth: "42.88px",
        InfoIcon: true,
        loanType:"refinancing",
        warrantyType: "dept",
        bgImage: BgRefinancing,
        gradient: '90deg, rgba(98,144,194,1) 0%, rgba(98,144,194,0.60) 45%',
        bgColor: '#6894C4',
        setting: {
            environment: process.env.REACT_APP_ENVIRONMENT || 'TEST'

        }
    },
]