import React, { useEffect } from 'react'
import { Stack, Container } from '@mui/system'
import { Card } from '@mui/material'
import { Typography } from '@material-ui/core'
import CardContent from '@mui/material/CardContent'
import Button from '../../Components/Button/Button'
import { useStyles } from './LoanHistoryStyles'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useNavigate } from 'react-router-dom'
import CompletedLoan from './CompletedLoan'
import DownloadIcon from '@mui/icons-material/Download'
import { useDispatch, useSelector } from 'react-redux'
import ArrowButton from '../ArrowButton/ArrowButton'
import { useMovil } from '../../hooks/useMovil'
import AlertInfo from '../Alert'
import AcordionOtherDocs from './AcordionOtherDocs'
import { useState } from 'react'
import CardCurrentLoan from '../AtomicDesign/organisms/CurrentLoanListt/CardCurrentLoan'
import ScheduleAppointment from './ScheduleAppoinment'
import status, { STATUS_ENUM, warrantyType } from '../status'
import moment from 'moment'
import { formatBZD } from '../../hooks/useFormatBZD'
// import { Interceptor } from '../../Utils/Interceptor'
// import axios from 'axios'
import { baseUrl } from '../../baseUrl'
import {
  ProcessArray,
  ProcessArraySecuredCash,
  ProcessArraySecuredVehicle,
  ProcessArraySecuredLand,
  ProcessArrayDebtRefinance
} from '../ProcessSteps/ProcessArray'
import { EmailPreviewLoading } from '../../Pages/components/skeleton'
import GetStatuses from '../../Store/Actions/GetStatuses'
import { clearState } from '../../Utils/InitState'

const LoanHistory = () => {
  const navigate = useNavigate()
  const { hasDownload, message } = useMovil('employmentletter')
  const { current, secure, unsecure, debtrefinancing, pending } = useSelector(
    (state) => state.StatusesReducer
  )
  // const AxiosInterceptor = Interceptor()
  const { SIGNED } = STATUS_ENUM

  const dispatch = useDispatch()
  const [code, setCode] = useState(() => {
    const savedCode = sessionStorage.getItem('code');
    return savedCode ? savedCode : '';
  })

  const [showModal, setShowModal] = useState(false)
  const [isValidStatus, setValid] = useState(false)
  const [actionBTN, setactionBTN] = useState(true)
  const [actionBTNUnsecure, setactionBTNUnsecure] = useState(true)
  const [urlStep, setUrlStep] = useState('')
  const [urlStepSecure, setUrlStepSecure] = useState('')
  const [urlStepDebtRefinancing, setUrlStepDebtRefinancing] = useState('')
  const [currentDetails, setcurrentDetails] = useState({
    startDate: '03/09/2022',
    submissionDate: '-- -- --',
    amount: 'BZ$ 10,000',
    termn: '12 months'
  })
  const [currentDetailsSecure, setcurrentDetailsSecure] = useState({
    startDate: '03/09/2022',
    submissionDate: '-- -- --',
    amount: 'BZ$ 10,000',
    termn: '12 months'
  })
  const [currentDetailsRefinance, setcurrentDetailsRefinance] = useState({
    startDate: '03/09/2022',
    submissionDate: '-- -- --',
    amount: 'BZ$ 10,000',
    termn: '12 months'
  })
  const [schedule, setSchedule] = useState(false)
  const [scheduleUnsecure, setScheduleUnsecure] = useState(false)
  const [scheduleDebtRefinancing, setScheduleDebtRefinancing] = useState(false)
  const [scheduleData, setScheduleData] = useState('')
  const [scheduleDataID, setScheduleDataID] = useState('')
  const [scheduleDataUnsecure, setScheduleDataUnsecure] = useState('')
  const [scheduleDatadebtRefinancing, setScheduleDatadebtRefinancing] =
    useState('')

  const classes = useStyles()

  // get schedule
  const getSchedule = () => {
    const fetchHeader = (token, loanTypeId) => {
      fetch(`${baseUrl}/schedule-appointments/customer`, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then((response) => response.json())
        .then((res) => {
          if (res) {
            switch (loanTypeId) {
              case 1:
                setScheduleDataUnsecure(
                  `${moment(res.appointmentDate).format(
                    'DD/MM/YY'
                  )} at ${moment(res.appointmentTime).format('LT')} in ${
                    res.branchName
                  }`
                )
                setScheduleDataID(res)
                setScheduleUnsecure(true)
                break
              case 2:
                setScheduleData(
                  `${moment(res.appointmentDate).format(
                    'DD/MM/YY'
                  )} at ${moment(res.appointmentTime).format('LT')} in ${
                    res.branchName
                  }`
                )
                setScheduleDataID(res)
                setSchedule(true)
                break
              case 3:
                setScheduleDatadebtRefinancing(
                  `${moment(res.appointmentDate).format(
                    'DD/MM/YY'
                  )} at ${moment(res.appointmentTime).format('LT')} in ${
                    res.branchName
                  }`
                )
                setScheduleDataID(res)
                setScheduleDebtRefinancing(true)
                break
              default:
                console.log('not set')
            }
          }
        })
    }

    if (secure.current[0]?.token.length) {
      sessionStorage.setItem('access_token', secure.current[0].token)
      const token = secure.current[0].token
      fetchHeader(token, secure.current[0].loanTypeId)
    }

    if (unsecure.current[0]?.token) {
      sessionStorage.setItem('access_token', unsecure.current[0].token)
      const token = unsecure.current[0].token
      fetchHeader(token, unsecure.current[0].loanTypeId)
    }

    if (debtrefinancing.current[0]?.token) {
      sessionStorage.setItem('access_token', debtrefinancing.current[0].token)
      const token = debtrefinancing.current[0].token
      fetchHeader(token, debtrefinancing.current[0].loanTypeId)
    }
  }

  const showScheduleAppointment = () => {
    setShowModal(true)
  }

  const downloadFile = () => {
    const file = current[0]?.employmentFile
    const link = document.createElement('a')
    link.download = `${'Letter' || ''}`
    link.href = file || ''
    link.click()
  }
  useEffect(() => {
    if (unsecure.current[0]?.status === null) {
      setValid(false)
      return
    }
    if (
      (typeof unsecure.current[0]?.status !== 'undefined' &&
        unsecure.current[0]?.status !== 0) ||
      (typeof secure.current[0]?.status !== 'undefined' &&
        secure.current[0]?.status !== 0) ||
      (typeof debtrefinancing.current[0]?.status !== 'undefined' &&
        debtrefinancing.current[0]?.status !== 0)
    ) {
      setValid(true)
    } else setValid(false)
    // document.body.style.backgroundColor = '#F0F0F0'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    unsecure.current[0]?.status,
    secure.current[0]?.status,
    debtrefinancing.current[0]?.status
  ])

  const warrantyTypeText = ['cash', 'vehicle', 'land']

  // set action for continue button
  const continueBtnAction = (currentLoan) => {
    // set url for secure card
    if (
      currentLoan.current[0]?.typeOfLoansRefSelected?.includes('Unsecured') &&
      currentLoan.current[0]?.typeOfLoansRefSelected?.includes('Secured') &&
      currentLoan.current[0]?.status === 3
    ) {
      navigate(`/Customer/ScheduleAppointment`)
      return
    }

    currentLoan.current[0]?.amount > 7500 &&
    currentLoan.current[0]?.status !== 6 &&
    currentLoan.current[0]?.status !== 0
      ? currentLoan.current[0]?.status === 13
        ? navigate(`/issued-offer`)
        : navigate(`/Customer/ScheduleAppointment`)
      : currentLoan.current[0]?.status === 6 ||
        // currentLoan.current[0]?.status === 3 ||
        currentLoan.current[0]?.status === 5 || // TODO: Consultar si debe ser mayor a 7k
        currentLoan.current[0]?.status === 13
      ? navigate(
          status[currentLoan.current[0]?.status].link +
            `?amount=` +
            currentLoan.current[0]?.amount
        )
      : navigate(
          currentLoan.current[0]?.status === 3
            ? (currentLoan.current[0]?.loanTypeId === 3 &&
                currentLoan.current[0]?.typeOfLoansRefSelected[0].includes(
                  'Secured'
                )) ||
              (currentLoan.current[0]?.loanTypeId === 1 &&
                currentLoan.current[0]?.amount > 7500) ||
              (currentLoan.current[0]?.loanTypeId === 2 &&
                currentLoan.current[0]?.amount > 7500)
              ? `/Customer/ScheduleAppointment`
              : status[currentLoan.current[0]?.status].link +
                `?amount=` +
                currentLoan.current[0]?.amount
            : currentLoan.current[0]?.loanTypeId === 1
            ? urlStep
            : currentLoan.current[0]?.loanTypeId === 2
            ? urlStepSecure
            : urlStepDebtRefinancing
        )

    if (currentLoan.current[0]?.loanTypeId === 2) {
      // set token secure
      sessionStorage.setItem('access_token', currentLoan.current[0]?.token)
      if (currentLoan.current[0].loanTypeId === 2) {
        sessionStorage.setItem('loan', 'secure')
        sessionStorage.setItem(
          'warrantyType',
          warrantyTypeText[secure.current[0].warrantyTypeId - 1]
        )
        sessionStorage.setItem(
          'warrantyTypeID',
          secure.current[0]?.warrantyTypeId
        )
      }
    }

    if (currentLoan.current[0]?.loanTypeId === 1) {
      sessionStorage.setItem('access_token', currentLoan.current[0]?.token)
      sessionStorage.setItem('loan', 'unsecure')
    }

    if (currentLoan.current[0]?.loanTypeId === 3) {
      sessionStorage.setItem('access_token', currentLoan.current[0]?.token)
      sessionStorage.setItem('loan', 'refinancing')
    }
  }

  useEffect(() => {
    if (
      secure.current[0]?.status === 5 &&
      !(secure.current[0]?.amount <= 7500)
    ) {
      setactionBTN(false)
      getSchedule()
    }

    if (
      secure.current[0]?.status === 6 &&
      !(secure.current[0]?.amount <= 7500)
    ) {
      setactionBTN(false)
      getSchedule()
    }

    if (
      unsecure.current[0]?.status === 5 &&
      !(unsecure.current[0]?.amount <= 7500)
    ) {
      setactionBTNUnsecure(false)
      getSchedule()
    }

    if (
      unsecure.current[0]?.status === 6 &&
      !(unsecure.current[0]?.amount <= 7500)
    ) {
      setactionBTN(false)
      getSchedule()
    }

    if (
      debtrefinancing.current[0]?.status === 5 &&
      (debtrefinancing.current[0]?.typeOfLoansRefSelected?.includes('Secured')) &&
      (debtrefinancing.current[0]?.amount <= 7500 || debtrefinancing.current[0]?.amount >= 7500)
    ) {
      setactionBTNUnsecure(false)
      getSchedule()
      // return
    }

    if (
      debtrefinancing.current[0]?.status === 5 &&
      (debtrefinancing.current[0]?.typeOfLoansRefSelected?.includes('Unsecured')) &&
      debtrefinancing.current[0]?.amount >= 7500
    ) {
      setactionBTNUnsecure(false)
      getSchedule()
      // return
    }

    if (
      debtrefinancing.current[0]?.status === 5 &&
        (
          debtrefinancing.current[0]?.typeOfLoansRefSelected?.includes('Unsecured') &&
          debtrefinancing.current[0]?.typeOfLoansRefSelected?.includes('Secured')
        )
    ) {
      console.log("Combinado")
      setactionBTNUnsecure(false)
      getSchedule()
      // return
    }

    if (
      debtrefinancing.current[0]?.status === 6 &&
      !(debtrefinancing.current[0]?.amount <= 7500)
    ) {
      setactionBTN(false)
      getSchedule()
    }

    // url step
    const getUrlStep = () => {
      let itemInfo
      if (secure.current !== null) {
        switch (secure.current[0]?.warrantyTypeId) {
          case 1:
            itemInfo = ProcessArraySecuredCash.filter((item) => {
              return item.id === secure.current[0]?.currentStep
            })
            setUrlStepSecure(itemInfo[0]?.Link)
            break
          case 2:
            itemInfo = ProcessArraySecuredVehicle.filter((item) => {
              return item.id === secure.current[0]?.currentStep
            })
            setUrlStepSecure(itemInfo[0]?.Link)
            break
          case 3:
            itemInfo = ProcessArraySecuredLand.filter((item) => {
              return item.id === secure.current[0]?.currentStep
            })
            setUrlStepSecure(itemInfo[0]?.Link)
            break
          default:
            setUrlStepSecure('/Customer/PersonalInformationReview')
            break
        }

        if (unsecure) {
          let itemInfoUnsecure = ProcessArray.filter((item) => {
            return item.id === unsecure.current[0]?.currentStep
          })
          setUrlStep(itemInfoUnsecure[0]?.Link)
        }

        if (debtrefinancing) {
          let itemInfoDebtRefinancing = ProcessArrayDebtRefinance.filter(
            (item) => {
              return item.id === debtrefinancing.current[0]?.currentStep
            }
          )
          setUrlStepDebtRefinancing(itemInfoDebtRefinancing[0]?.Link)
        }
      }

      if (secure.current[0]) {
        setcurrentDetailsSecure({
          startDate:
            moment(secure.current[0]?.startDate).format('DD/MM/YY') ||
            '-- -- --',
          submissionDate:
            moment(secure.current[0]?.submissionDate).format('DD/MM/YY') ||
            '-- -- --',
          amount: `BZ$ ${formatBZD(secure.current[0].amount)}`,
          termn: `${secure.current[0].term} months`
        })
      }

      if (unsecure.current[0]) {
        setcurrentDetails({
          startDate:
            moment(unsecure.current[0]?.startDate).format('DD/MM/YY') ||
            '-- -- --',
          submissionDate:
            moment(unsecure.current[0]?.submissionDate).format('DD/MM/YY') ||
            '-- -- --',
          amount: `BZ$ ${formatBZD(unsecure.current[0]?.amount)}`,
          termn: `${unsecure.current[0]?.term} months`
        })
      }

      if (debtrefinancing.current[0]) {
        setcurrentDetailsRefinance({
          startDate:
            moment(debtrefinancing.current[0]?.startDate).format('DD/MM/YY') ||
            '-- -- --',
          submissionDate:
            moment(debtrefinancing.current[0]?.submissionDate).format(
              'DD/MM/YY'
            ) || '-- -- --',
          amount: `BZ$ ${formatBZD(debtrefinancing.current[0]?.amount)}`,
          termn: `${debtrefinancing.current[0]?.term} months`
        })
      }
    }

    if (
      secure.current[0]?.status !== undefined ||
      unsecure.current[0]?.status !== undefined ||
      debtrefinancing.current[0]?.status !== undefined
    ) {
      getUrlStep()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secure, unsecure, setSchedule, debtrefinancing])


  useEffect(() => {
    if (secure.current[0]?.token)
      sessionStorage.setItem('access_token', secure.current[0]?.token)

    if (debtrefinancing.current[0]?.token)
      sessionStorage.setItem('access_token', debtrefinancing.current[0]?.token)

    if (unsecure.current[0]?.token)
      sessionStorage.setItem('access_token', unsecure.current[0]?.token)
  }, [secure, unsecure, debtrefinancing])

  useEffect(() => {
      const filterCurrent = {
        where: {
          and: [ { or: [ {status: 0}, {status: 1}, {status: 2}, {status: 3}, {status: 5}, {status: 6}, {status: 7}, {status: 12}, {status: 13} ] } ]
        }
      }
      
      const filterCompleted = {
        where: {
          and: [ { or: [ {status: 4}, {status: 8}, {status: 9}, {status: 10}, {status: 11}, {status: 14}, {status: 15} ] } ]
        }
      }

      if (code) {
        dispatch(GetStatuses({ token: code, filter: filterCurrent }, 1));
        dispatch(GetStatuses({ token: code, filter: filterCompleted }, 2));
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    clearState(dispatch)
  }, [])

  return (
    <Container className={classes.CustomContainer}>
      <Card variant='outlined' className={classes.Card}>
        <CardContent>
          <Typography className={classes.Header}>MY LOAN HISTORY</Typography>
        </CardContent>
      </Card>
      <Card variant='outlined' className={classes.Card}>
        <CardContent>
          <Stack
            spacing={{ xs: 4, md: 2 }}
            direction='column'
            mb={3}
            width='100%'
          >
            {/* // ! COMPLETE */}
            <Accordion elevation={0} className={classes.Accordian}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body2' className={classes.AccordianHeader}>
                  MY COMPLETED ONLINE LOANS
                </Typography>
              </AccordionSummary>
              {pending && ( <EmailPreviewLoading /> )}
              {!pending && (
                <AccordionDetails
                  sx={{ backgroundColor: '#FFFF', padding: 0 }}
                  mt={3}
                >
                  <CompletedLoan />
                </AccordionDetails>
              )}
            </Accordion>

            {/* // ! CURRENT */}
            <Accordion elevation={0} className={classes.Accordian}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body2' className={classes.AccordianHeader}>
                  MY CURRENT ONLINE LOAN APPLICATIONS
                </Typography>
              </AccordionSummary>
              {!pending && (
                <AccordionDetails
                style={{
                  gap: '2rem',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                sx={{ backgroundColor: '#FFFF', padding: 10 }}
                mt={3}
              >
                {(secure.current.length &&
                  secure.current[0]?.status === 0 &&
                  String(secure.current[0]?.startDate).length > 0) ||
                secure.current[0]?.status === 1 ||
                secure.current[0]?.status === 2 ||
                secure.current[0]?.status === 3 ||
                secure.current[0]?.status === 5 ||
                secure.current[0]?.status === 6 ||
                secure.current[0]?.status === 9 ||
                secure.current[0]?.status === 12 ||
                secure.current[0]?.status === 13 ? (
                  <CardCurrentLoan
                    ShowInfoCard={
                      schedule &&
                      secure.current[0]?.status === 5 &&
                      secure.current[0]?.amount > 7500
                        ? true
                        : secure.current[0]?.status === 9
                        ? true
                        : false
                    }
                    TypeInfoCard={
                      schedule &&
                      secure.current[0]?.status === 5 &&
                      secure.current[0]?.amount > 7500
                        ? 'Success'
                        : 'Error'
                    }
                    CurrentDetails={currentDetailsSecure}
                    LoanType={'SECURED'}
                    LoanStatus={status[secure.current[0].status].label}
                    warrantyType={
                      warrantyType[secure.current[0].warrantyTypeId]?.name
                    }
                    actionCardInfo={showScheduleAppointment}
                    actionContinue={() => continueBtnAction(secure)}
                    ShowContinueButton={
                      ![SIGNED].includes(secure.current[0]?.status) && status[secure.current[0]?.status]?.button && actionBTN
                    }
                    AppointmentDate={scheduleData}
                    approvalLetter={secure.current[0]?.approvalLetter}
                    code={secure.current[0].token}
                  />
                ) : (
                  ''
                )}

                {(unsecure.current.length &&
                  unsecure.current[0]?.status === 0 &&
                  String(unsecure.current[0]?.startDate).length > 0) ||
                unsecure.current[0]?.status === 1 ||
                unsecure.current[0]?.status === 2 ||
                unsecure.current[0]?.status === 3 ||
                unsecure.current[0]?.status === 5 ||
                unsecure.current[0]?.status === 6 ||
                unsecure.current[0]?.status === 9 ||
                unsecure.current[0]?.status === 12 ? (
                  <CardCurrentLoan
                    ShowInfoCard={
                      scheduleUnsecure &&
                      unsecure.current[0]?.status === 5 &&
                      unsecure.current[0]?.amount > 7500
                        ? true
                        : unsecure.current[0]?.status === 9
                        ? true
                        : false
                    }
                    TypeInfoCard={
                      scheduleUnsecure &&
                      unsecure.current[0]?.status === 5 &&
                      unsecure.current[0]?.amount > 7500
                        ? 'Success'
                        : 'Error'
                    }
                    CurrentDetails={currentDetails}
                    LoanType={'UNSECURED'}
                    LoanStatus={status[unsecure.current[0].status].label}
                    warrantyType={'PERSONAL LOAN'}
                    actionCardInfo={showScheduleAppointment}
                    actionContinue={() => continueBtnAction(unsecure)}
                    ShowContinueButton={
                      ![SIGNED].includes(unsecure.current[0]?.status) && status[unsecure.current[0]?.status]?.button &&
                      actionBTNUnsecure
                    }
                    AppointmentDate={scheduleDataUnsecure}
                    approvalLetter={unsecure.current[0]?.approvalLetter}
                  />
                ) : (
                  ''
                )}

                {(debtrefinancing.current.length &&
                  debtrefinancing.current[0]?.status === 0 &&
                  String(debtrefinancing.current[0]?.startDate).length > 0) ||
                debtrefinancing.current[0]?.status === 1 ||
                debtrefinancing.current[0]?.status === 2 ||
                debtrefinancing.current[0]?.status === 3 ||
                debtrefinancing.current[0]?.status === 5 ||
                debtrefinancing.current[0]?.status === 6 ||
                debtrefinancing.current[0]?.status === 9 ||
                debtrefinancing.current[0]?.status === 12 ? (
                  <CardCurrentLoan
                    ShowInfoCard={
                      scheduleDebtRefinancing &&
                      debtrefinancing.current[0]?.status === 5 &&
                      (debtrefinancing.current[0]?.amount > 7500 || debtrefinancing.current[0]?.amount <= 7500)
                        ? true
                        : debtrefinancing.current[0]?.status === 9
                        ? true
                        : false
                    }
                    TypeInfoCard={
                      scheduleDebtRefinancing &&
                      debtrefinancing.current[0]?.status === 5 &&
                      (debtrefinancing.current[0]?.amount > 7500 || debtrefinancing.current[0]?.amount <= 7500)
                        ? 'Success'
                        : 'Error'
                    }
                    CurrentDetails={currentDetailsRefinance}
                    LoanType={'DEBT REFINANCING'}
                    LoanStatus={status[debtrefinancing.current[0].status].label}
                    warrantyType={''}
                    actionCardInfo={showScheduleAppointment}
                    actionContinue={() => continueBtnAction(debtrefinancing)}
                    ShowContinueButton={
                      ![SIGNED].includes(debtrefinancing.current[0]?.status) && status[debtrefinancing.current[0]?.status]?.button &&
                      actionBTNUnsecure
                    }
                    AppointmentDate={scheduleDatadebtRefinancing}
                    approvalLetter={debtrefinancing.current[0]?.approvalLetter}
                  />
                ) : (
                  ''
                )}
                </AccordionDetails>
              )}
              {pending && ( <EmailPreviewLoading /> )}
              {!pending && !(secure.current.length > 0 || unsecure.current.length > 0 || debtrefinancing.current.length > 0 ) && (
                <Typography variant='body2' className={classes.AccordianHeader}>
                  No current loan applications
                </Typography>
            ) }
            </Accordion>

            {/* // ! MY VALID DOCUMENTS */}
            <Accordion elevation={0} className={classes.Accordian}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body2' className={classes.AccordianHeader}>
                  MY VALID LOAN DOCUMENTS
                </Typography>
              </AccordionSummary>
              {current[0]?.employmentFile ? (
                <AccordionDetails
                  sx={{ backgroundColor: '#FFFF', padding: 10 }}
                  mt={3}
                >
                  <Stack
                    direction={{ xs: 'column', sm: 'column', md: 'row' }}
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Stack
                      direction='column'
                      width={{ xs: '100%', sm: '49%' }}
                      mt={2}
                    >
                      {/* <a
                      href={current[0]?.employmentFile}
                      download
                      // style={{ textDecoration: 'none' }}
                    > */}
                      {hasDownload ? (
                        <Button
                          Title='EMPLOYMENT LETTER'
                          variant='h7'
                          className={classes.UploadFiles}
                          onClick={downloadFile}
                          endIcon={
                            <DownloadIcon sx={{ fill: 'black !important' }} />
                          }
                        />
                      ) : (
                        <AlertInfo content={message} />
                      )}
                      {/* </a> */}
                      <>
                        {/* <input
                        type="file"
                        accept="application/pdf,image/*"
                        className={classes.MonthlyCreditInput}
                        value=""
                        style={{ display: 'none' }}
                      /> */}
                      </>
                    </Stack>
                    <Stack width='50%'>
                      <Stack justifyContent='center' alignItems='center'>
                        <Typography
                          className={classes.validity}
                          style={{ color: '#9C9B9B' }}
                        >
                          VALIDITY
                        </Typography>
                      </Stack>
                      <Stack
                        direction='row'
                        spacing={4}
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Stack justifyContent='center' alignItems='center'>
                          <Box className={classes.Numbers}>
                            {current[0]?.months}
                          </Box>
                          <Typography className={classes.numberText}>
                            MONTHS
                          </Typography>
                        </Stack>
                        <Stack justifyContent='center' alignItems='center'>
                          <Box className={classes.Numbers}>
                            {current[0]?.days}
                          </Box>
                          <Typography className={classes.numberText}>
                            DAYS
                          </Typography>
                        </Stack>
                        <Stack justifyContent='center' alignItems='center'>
                          <Box className={classes.Numbers}>
                            {current[0]?.hours}
                          </Box>
                          <Typography className={classes.numberText}>
                            HOURS
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </AccordionDetails>
              ) : (
                <Typography></Typography>
              )}
            </Accordion>
            {/*  */}

            <Accordion elevation={0} className={classes.Accordian}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='body2' className={classes.AccordianHeader}>
                  OTHER DOCS
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <AcordionOtherDocs />
              </AccordionDetails>
            </Accordion>

            {/* //! Button BACK */}
            <Stack justifyContent='center' alignItems='center'>
              <Box className={classes.SubmitBtn} mb={2}>
                <Button
                  Title='BACK'
                  onClick={() =>
                    navigate(`/?q=${sessionStorage.getItem('code')}`)
                  }
                />
              </Box>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      {isValidStatus && <ArrowButton link='' back={''} />}
      {showModal && (
        <ScheduleAppointment
          data={scheduleDataID}
          setShowModal={setShowModal}
          customAction={() => getSchedule()}
        />
      )}
    </Container>
  )
}
export default LoanHistory
