import React from "react";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { useStyles } from "./IdleTimeOutModalStyle"

const IdleTimeOutModal  = ({showModal, handleClose, title, errorMessage}) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={showModal}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}>{title}</Box>
       <Box className={classes.AlertMainSection} mt={4}>
         <Typography className={classes.AlertText}>
          {errorMessage}
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={handleClose}
          >
            ACCEPT
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default IdleTimeOutModal;
