import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";

export const useStyles = makeStyles((theme) => ({

  h2: {
    color: "#275DA6 "
  },

  responsiveH2: {
    color: theme.colors.primary.Darkblue,
    fontFamily: theme.fonts.family.quaternary,
    // fontWeight: 500,
    fontSize: 15,
    // Estilos para pantallas más pequeñas
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem !important',
    },
  },

  buttonActive: {
    backgroundColor: "#275DA6 !important", // Mantener el mismo color del tooltip
    color: "#fff !important", // Mantener el mismo color del tooltip
    borderRadius: "10px !important",
    '&:hover': {
      backgroundColor: theme.palette.primary.main, // Mantener el mismo color en el hover
    },
  },

  buttonInactive: {
    backgroundColor: "#f4ebfe !important", // Mantener el mismo color del tooltip
    color: "#275DA6 !important", // Mantener el mismo color del tooltip
    borderRadius: "10px !important",
    '&:hover': {
      backgroundColor: theme.palette.primary.main, // Mantener el mismo color en el hover
    },
  },
  Card: {
    marginTop: "30px"
  },
  SecondButton: {
    "&.MuiButtonBase-root": {
      height: 'auto',
      width: '100%',

      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        // backgroundColor: '#F4EBFE !important',
        // color: theme.colors.primary.Darkblue
      },
      "& .MuiTypography-root": {
        fontSize: "14.96px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 1100px)": {
        margin: "0",
      },
      "@media (max-width: 500px)": {
        width: "100%",
        marginBottom: 20,
      },
    },
  },
  GreenButton: {
    "&.MuiButtonBase-root": {
      borderRadius: '21.9px',
      backgroundColor: '#53AF32',
      padding: '0.25rem 2rem',
      marginBottom: '3rem',
      display: 'block',
      "&:hover": {
        backgroundColor: '#53AF32',
        cursor: 'unset'
      },
      "& .MuiTypography-root": {
        fontSize: "14.96px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 1100px)": {
        // margin: "0",
      },
      "@media (max-width: 500px)": {
        width: "100%",
        marginBottom: 20,
      },
    },
  },
  Header: {
    fontFamily: theme.fonts.family.primary,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '25px',
    color: '#275DA6',
    // textAlign: "center"
    '@media (max-width: 900px)': {
      lineHeight: '1.3em'
    },
},
Text: {
  fontFamily: theme.fonts.family.primary,
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '22px',
  lineHeight: '20px',
  color: '#3C3C3B',
  marginTop: '25px',
  // textAlign: "center"
},
}));

