import React, { useEffect, useState } from 'react'
import Title from '../../atoms/Title/Title'
import Button from '../../../Button/Button'
import { Grid, Typography } from '@mui/material'
import ModalContainer from '../../atoms/ModalContainer/ModalContainer'
import SmilingFace from '../../../../assets/Images/smiling-face.png'
import { baseUrl } from '../../../../baseUrl'
import { useMovil } from '../../../../hooks/useMovil'

//styles
import { useStyles } from './HeaderCurrentLoanStyles'
import AlertInfo from '../../../Alert'

const HeaderCurrentLoan = ({
  warrantyType,
  LoanType,
  LoanStatus,
  Action,
  ShowContinueButton,
  ApprovalLetter,
  code
}) => {
  const classes = useStyles()
  const { movil, message } = useMovil('downloadApprovalLetter')

  const isDownloadedLetter = (isDownloaded) => {
    if(code){
      fetch(`${baseUrl}/letter-download-check`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${code}`
        },
        body: JSON.stringify({ isDownloaded })
      })
        .then((res) => res.json())
        .then((data) => setIsDocDownloaded(data.hasBeenDownloaded))
    }
  }

  useEffect(() => {
    isDownloadedLetter(null)
  }, [])

  const [isDocDownloaded, setIsDocDownloaded] = useState()
  const [isOpenModal, setisOpenModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  return (
    <Grid container justifyContent={'space-between'} alignItems={'center'}>
      <Grid
        className={classes.mobileHeader}
        container
        xs={6}
        md={6}
        direction='column'
        justifyContent='flex-start'
      >
        <Grid item xs={12} md={12}>
          <Typography className={classes.CustomTitle}>
            NEW LOAN APPLICATION
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-end'
          xs={12}
          md={12}
        >
          <Title
            className={classes.Title}
            Text={warrantyType}
            margin={'0px'}
            fontWeight={'100'}
            fontSize={'12px'}
          />
          {LoanType === 'DEBT REFINANCING' ? (
            ''
          ) : (
            <span className={classes.separator}>-</span>
          )}
          <Title
            Text={LoanType}
            margin={'0px'}
            fontWeight={'100'}
            fontSize={'12px'}
          />
          <span className={classes.separator}>•</span>
          <Title
            Text={LoanStatus}
            margin={'0px'}
            color={'#60b43e'}
            fontWeight={'100'}
            fontSize={'12px'}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='flex-end'
        xs={6}
        md={6}
        className={classes.BtnContainer}
      >
        {ApprovalLetter !== 'null' &&
          ApprovalLetter !== 'undefined' &&
          LoanStatus === 'APPROVED' && (
            <a href={!isDocDownloaded && !movil &&ApprovalLetter} download='APPROVAL LETTER'>
              <Button
                className={
                  isDocDownloaded ? classes.isDownloaded : classes.ConfirmButton
                }
                Title={
                  isDocDownloaded
                    ? 'Approval Letter'
                    : 'Download Approval Letter'
                }
                variant='subtitle1'
                BtnVariant='contained'
                onClick={() => {
                  if(!isDocDownloaded && movil) {
                    setShowAlert(true)
                    return
                  }
                  isDownloadedLetter(true)
                  sessionStorage.setItem('downloaded', 'true')
                  setisOpenModal(true)
                }}
                color='primary'
                // style={{ width: '300px' }}
              />
            </a>
          )}
        {ShowContinueButton && (
          <Button
            disabled={
              LoanType === 'SECURED' &&
              warrantyType === 'VEHICLE LOAN' &&
              LoanStatus === 'APPROVED' &&
              !isDocDownloaded
            }
            className={classes.ConfirmButton}
            Title='Continue'
            variant='subtitle1'
            BtnVariant='contained'
            onClick={Action}
            color='primary'
            // style={{ width: '300px' }}
          />
        )}
      </Grid>
      {
        showAlert && <AlertInfo content={message} />
      }

      <ModalContainer open={isOpenModal}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '1rem 2rem',
            gap: '1rem',
            maxWidth: '400px'
          }}
        >
          <img width={100} src={SmilingFace} alt='' />
          <Typography color={'#005FAA'} fontWeight={'700'} fontSize={'24px'}>
            CONGRATULATIONS!
          </Typography>
          <Typography color={'#706F6F'} fontSize={'14.5px'}>
            You're one step closer to completing your loan request. The letter
            serves as evidence to the seller of your loan approval. <br />{' '}
            <br /> Please go with the seller to the transport department to
            transfer the vehicle title to your name. When you have the title,
            please schedule an appointment online to finalize the loan process.
          </Typography>
          <Button
            style={{ marginBottom: '1rem' }}
            className={classes.ConfirmButton}
            Title='OKAY'
            variant='subtitle1'
            BtnVariant='contained'
            onClick={() => setisOpenModal(false)}
            color='primary'
          />
        </div>
      </ModalContainer>
    </Grid>
  )
}

export default HeaderCurrentLoan
