import React, { useState, useEffect } from 'react'
import { useStyles } from './MonthlyInstallmentsStyle'
import { Box, Stack } from '@mui/system'
import { Typography } from '@mui/material'
import LoanDetails from './components/LoanDetails/LoanDetails'
//import PersonalOnlineLoan from '../../PersonalOnlineLoan/PersonalOnlineLoan'
import LoanCalculator from './components/LoanCalculator/LoanCalculator'
import ArrowButton from '../../../../Components/ArrowButton/ArrowButton'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GetLoandRequest } from '../../../../Store/Actions/GetLoandRequest'
import { useRef } from 'react'
import LocalStorageService from '../../../../Utils/LocalStoreServices'
import Actions from '../../../../Store/Types'
//import Button from '../../Button/Button'
import { loanRequest } from '../../../../Store/Actions/LoanRequest'
//import axios from 'axios'
// { baseUrl } from '../../../baseUrl'
import { Interceptor } from '../../../../Utils/Interceptor'
import { warrantyTypeNames } from '../../../../Components/status'
import { hasGetLoanRequest } from '../../../../Store/context/getLoanRequest'

const LoanCalculation = () => {
  const navigate = useNavigate()
  const AxiosInterceptor = Interceptor()
  const {
    requestedAmount,
    requestedTerms,
    amount: monto,
    terms: terminos,
    typeOfCollateral,
    debtCancellationSchemeRequested,
    loanTypeId,
    warrantyTypeId,
    landValue: valueOfland,
    legalFeesTypeId
  } = useSelector((state) => state.LoanRequestReducer)
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer)

  const { typeOfLoansRefSelected, totalColCoverValue } = useSelector(
    (state) => state.getLoanRequest
  )

  useEffect(() => {
    dispatch(hasGetLoanRequest())
  }, [])

  const Location = useLocation()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [amount, setAmount] = useState()
  const [landValue, setLandValue] = useState()
  const [typeOfLandTransfer, setTypeOfLandTransfer] = useState('')
  const [terms, setTerms] = useState()
  const [error, setError] = useState('')
  const [errorTerms, setErrorTerms] = useState('')
  const [nextLink, setNextLink] = useState('#')
  const [showButtonLink, setShowButtonLink] = useState(true)
  const [disabledContinue, setdisabledContinue] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [showPersonal, setShowPersonal] = useState({
    show: false,
    message: ''
  })
  const [cancellationSchemeRequested, setCancellationSchemeRequested] =
    useState(debtCancellationSchemeRequested)

  useEffect(() => {
    const formData = {
      requestedAmount: Number(amount),
      requestedTerms: Number(terms),
      landValue: Number(landValue),
      // promoCode: promoCodeValue,
      interestRate: 1,
      loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
      // debtCancellationSchemeRequested: false,
      warrantyTypeId:
        sessionStorage.getItem('loan') === 'secure'
          ? warrantyTypeNames.indexOf(sessionStorage.getItem('warrantyType')) + 1
          : null
    }
    // if (amount > 30000 && terms >= 12) {
    //   dispatch(loanRequest(formData))
    // }

    // if (amount >= 1000 && terms > 60) {
    //   dispatch(loanRequest(formData))
    // }

    if (
      typeOfCollateral === 'partially' &&
      amount >= 1000 &&
      terms <= 60 &&
      terms >= 12
    ) {
      console.log('sending partially ...')
      dispatch(loanRequest(formData))
    }

    if (
      typeOfCollateral === 'fully' &&
      amount >= 1000 &&
      terms >= 12 &&
      terms <= 120
    ) {
      console.log('sending fully ...')
      dispatch(loanRequest(formData))
    }

    if (typeOfCollateral === 'partially' && (terms > 60 || terms < 12)) {
      setdisabledContinue(true)
    }
    if (typeOfCollateral === 'fully' && (terms > 120 || terms < 12)) {
      setdisabledContinue(true)
    }
    if (typeOfCollateral === 'partially' && terms <= 60 && terms >= 12) {
      setdisabledContinue(false)
    }
    if (typeOfCollateral === 'fully' && terms <= 120 && terms >= 12) {
      setdisabledContinue(false)
    }
  }, [terms, amount, landValue])


  const countPage = useRef(0)

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  useEffect(() => {
    if (dataSchedule.emailSent ?? false) {
      setShowButtonLink(false)
      // LocalStorageService.clearToken()
    }
  }, [dataSchedule])

  useEffect(() => {
    if (countPage.current === 0 && requestedAmount > 0 && requestedTerms > 60) {
      if (sessionStorage.getItem('warrantyType') === 'cash') {
        setNextLink('/Customer/LoanPurpose')
      } else {
        setNextLink('/Customer/MonthlyCreditObligations')
      }
      return
    }
    if (amount > 0 && terms > 0) {
      if (sessionStorage.getItem('warrantyType') === 'cash') {
        setNextLink('/Customer/LoanPurpose')
      } else {
        setNextLink('/Customer/MonthlyCreditObligations')
      }
    } else setNextLink('#')
  }, [amount, terms])

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch({ type: Actions.CLEARLOANREQUESTSTATE })
    dispatch(GetLoandRequest())
  }, [])
  useEffect(() => {
    // setAmount(Number(monto))
    setLandValue(Number(valueOfland))
    setTypeOfLandTransfer(Number(legalFeesTypeId))
    //setAmount(5000)
  }, [monto, valueOfland, legalFeesTypeId])

  useEffect(() => {
    setTerms(Number(requestedTerms))
    setAmount(Number(requestedAmount))
    //setTerms(500)
  }, [requestedAmount, requestedTerms])
  const handleSubmitData = () => {
    //postCashLoanCollateral(data)
    AxiosInterceptor.post('/customers/step', { currentStep: 3 })
      .then((response) => {
        if (
          sessionStorage.getItem('loan') === 'secure' &&
          sessionStorage.getItem('warrantyType') === 'vehicle'
        ) {
          navigate('/Customer/vehicle-status')
        }
        if (
          sessionStorage.getItem('loan') === 'secure' &&
          sessionStorage.getItem('warrantyType') === 'land'
        ) {
          navigate('/Customer/land-details')
        }
        if (sessionStorage.getItem('loan') === 'refinancing') {
          navigate('/Customer/monthlyIncome')
        }
      })
      .catch((e) => console.log(e))
    // navigate('/Customer/LoanPurpose')
  }

  const handleBackBtn = () => {
    // if(sessionStorage.getItem('loan') === 'secure' && sessionStorage.getItem('warrantyType') === 'land'){
    //   AxiosInterceptor.post('/customers/step', { currentStep: 1 })
    //   .then((response) => {
    //     navigate('/Customer/PersonalInformationReview')
    //   })
    //   .catch((e) => console.log(e))
    // }

    if (sessionStorage.getItem('loan') === 'refinancing') {
      AxiosInterceptor.post('/customers/step', { currentStep: 2 })
        .then((response) => {
          navigate('/Customer/loans-to-be-refinanced')
        })
        .catch((e) => console.log(e))
    }
  }

  return (
    <Box>
      {Location.pathname === '/Customer/yourLoanRequest/TermsAndConditions' ? (
        <Outlet />
      ) : (
        <Box>
          <Box className={classes.LoanContainer}>
            {/* {sessionStorage.getItem('loan') === 'refinancing' && (
              <Box className={classes.AlertVehicleUpload}>
                <Typography>
                  Dear {sessionStorage.getItem('name')}, <br /> Please note that the Requested Loan Amount
                  plus fees should not exceed the Maximum New Funds Available.
                  If your total exceeds this amount, your Requested Loan Amount
                  may be reduced.
                </Typography>
              </Box>
            )} */}
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 2, sm: 2, md: 10, lg: 20 }}
              width='100%'
            >
              <LoanDetails
                totalColCoverValue={totalColCoverValue}
                setLandValue={setLandValue}
                landValue={landValue}
                typeOfLandTransfer={typeOfLandTransfer}
                setTypeOfLandTransfer={setTypeOfLandTransfer}
                setAmount={setAmount}
                amount={Number(amount)}
                terms={Number(terms)} //terms
                setTerms={setTerms}
                error={error}
                errorTerms={errorTerms}
                typeLoan={'SECURE'}
                typeOfCollateral={typeOfCollateral} // partially or  typeOfCollateral collateralTest
                disabledContinue={setdisabledContinue}
                messageErrorInButtonContinue={setErrorMessage}
              />
            </Stack>
            {amount || terms ? (
              <>
                <LoanCalculator
                  setLandValue={setLandValue}
                  typeOfLoansRefSelected={typeOfLoansRefSelected}
                  landValue={landValue}
                  typeOfLandTransfer={typeOfLandTransfer}
                  amount={Number(amount)}
                  terms={Number(terms)}
                  setError={setError}
                  setErrorTerms={setErrorTerms}
                  loanType={'secure'}
                  typeOfCollateral={typeOfCollateral}
                  cancellationSchemeRequested={setCancellationSchemeRequested}
                />
              </>
            ) : (
              <LoanCalculator
                setLandValue={setLandValue}
                landValue={landValue}
                // purchasePrice={Number(setLandValue)}
                amount={Number(amount)}
                terms={Number(terms)}
                setError={setError}
                setErrorTerms={setErrorTerms}
                loanType={'secure'}
                typeOfCollateral={typeOfCollateral}
                cancellationSchemeRequested={setCancellationSchemeRequested}
              />
            )}
          </Box>

          {showButtonLink && (
            <>
              {/* <ArrowButton link="/Customer/LoanPurpose"/> */}
              <ArrowButton
                onClickBackBtn={() => handleBackBtn()}
                onClick={() => handleSubmitData()}
                // disabled={
                // sessionStorage.getItem('loan') === 'secure' &&
                // sessionStorage.getItem('warrantyType') === 'vehicle' &&
                // (Number(requestedAmount) < 7500 || Number(requestedAmount) > 50000 || Number(requestedTerms > 120))
                // }
                disabled={ disabledContinue ? true :
                  ((!cancellationSchemeRequested) &&
                    sessionStorage.getItem('loan') === 'secure' &&
                    sessionStorage.getItem('warrantyType') === 'vehicle' &&
                    (Number(requestedAmount) < 7500 ||
                      Number(requestedAmount) > 50000 ||
                      Number(requestedTerms > 120))) ||
                  (sessionStorage.getItem('loan') === 'secure' &&
                    sessionStorage.getItem('warrantyType') === 'land' &&
                    Number(requestedAmount) < 7500)
                }
                msg={
                  sessionStorage.getItem('loan') === 'secure' &&
                  sessionStorage.getItem('warrantyType') === 'vehicle'
                    ? 'The loan amount must be greater than $7,500 but not exceeding $50,000 and the term must be equal to or lower than 120 months.'
                    : sessionStorage.getItem('loan') === 'secure' &&
                      sessionStorage.getItem('warrantyType') === 'land'
                    ? errorMessage ? "Please correct the errors before continuing" :'The purchase price must be greater than $7,500 but not exceeding $150,000 and the term must be equal to or lower than 120 months.'
                    : ''
                }
              />
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default LoanCalculation
