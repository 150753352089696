import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hasDistrictData } from '../../../Store/context/getDistrict'
import { hasUrbanAreas } from '../../../Store/context/getUrbanAreas'
import { hasRegistrationSection } from '../../../Store/context/getRegistrationSection'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import Select from '@mui/material/Select'
import { useStyles } from './ScheduleAppointmentStyle'
import ClearIcon from '@mui/icons-material/Clear'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Field } from 'formik'
import Button from '../../Button/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material'
import InfoIcon from '../../../assets/Images/icon-blue.png'

const LandLoanData = ({ Files, formik, landDetails }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const inputLandTitleRef = useRef(null)
  const inputLandAppraisalRef = useRef(null)
  const inputPurchaseAgrementRef = useRef(null)
  const [selectedDistrict, setselectedDistrict] = useState()
  const [selectedUrbanArea, setSelectedUrbanArea] = useState()
  const [selectedRegsitrationSection, setselectedRegsitrationSection] =
    useState()
  const [selectedStreet, setselectedStreet] = useState()
  const [selectedParcelNum, setselectedParcelNum] = useState()
  const { districts } = useSelector((state) => state.districtData)
  const { urbanAreas } = useSelector((state) => state.urbanAreas)
  const { data: registrationSectionData } = useSelector(
    (state) => state.registrationSection
  )

  const [showTooltip, setShowTooltip] = useState(false)

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#0F5CAC',
      fontSize: 14,
      maxWidth: 261,
      filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
      padding: '1rem'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      '&::before': {
        backgroundColor: 'white'
      }
    }
  }))

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  useEffect(() => {
    dispatch(hasDistrictData())
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDistrict, landDetails?.districtId])

  const onlandTitleRefClick = () => {
    inputLandTitleRef.current.click()
    formik.setFieldTouched('landTitle')
  }

  const onlandAppraisalRefClick = () => {
    inputLandAppraisalRef.current.click()
    formik.setFieldTouched('landAppraisal')
  }

  const onPurchaseAgreementPictureRefClick = () => {
    inputPurchaseAgrementRef.current.click()
    formik.setFieldTouched('purchaseAgreement')
  }

  useEffect(() => {
    if (landDetails?.districtId > 0 && landDetails?.urbanAreaId) {
      dispatch(hasUrbanAreas(landDetails?.districtId)).then(() =>
        setSelectedUrbanArea(landDetails?.urbanAreaId)
      )
    }

    console.log(landDetails)

    if (landDetails?.districtId > 0 && landDetails?.registrationSectionId) {
      dispatch(hasRegistrationSection(landDetails?.districtId)).then(() => {
        setselectedRegsitrationSection(landDetails?.registrationSectionId)
      })
    }
  }, [landDetails?.districtId, landDetails?.urbanAreaId, landDetails?.registrationSectionId])

  return (
    <>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* DISTRICT SELECTION */}
        <Grid item xs={12} md={6}>
          {((!Files?.districtId && districts) ||
            (Files?.districtId &&
              window.location.pathname === '/Customer/land-details')) && (
            <>
              <label className={classes.UploadLabelVehicle}>
                DISTRICT
                {window.location.pathname === '/Customer/land-details'
                  ? '*'
                  : ''}
              </label>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  sx={{ top: '-6px', color: '#B2B2B2', fontSize: '0.95rem' }}
                  shrink={false}
                  id='select-district-label'
                >
                  {selectedDistrict === undefined &&
                    landDetails.districtId === null &&
                    'Select district'}
                </InputLabel>
                <Field name='districtId'>
                  {({ field, form, meta }) => {
                    return (
                      <Select
                        labelId='select-district-label'
                        sx={{
                          width: '100%',
                          my: 2,
                          display: 'block',
                          cursor: 'default',
                          height: '2.7rem',
                          marginTop: '0',
                          '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            paddingTop: '10px !important'
                          }
                        }}
                        id={'select-district'}
                        value={
                          selectedDistrict
                            ? selectedDistrict
                            : landDetails.districtId
                        }
                        defaultValue={''}
                        onChange={(e) => {
                          setselectedDistrict(e.target.value)
                          dispatch(
                            hasUrbanAreas(e.target.value)
                          )
                          dispatch(hasRegistrationSection(e.target.value))
                          formik.setFieldTouched('districtId')
                          form.setFieldValue('districtId', e.target.value)
                        }}
                        // onClick={() => dispatch(hasUrbanAreas(selectedDistrict))}
                      >
                        {districts.map((district) => {
                          return (
                            <MenuItem
                              defaultValue={''}
                              value={district.id}
                              key={district.id}
                            >
                              {district.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )
                  }}
                </Field>
              </FormControl>
            </>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {/* CITY SELECTION */}
          {(!Files?.cityTownVillage ||
            (Files?.cityTownVillage &&
              window.location.pathname === '/Customer/land-details')) && (
            <>
              <label className={classes.UploadLabelVehicle}>
                City/Town/Village
                {window.location.pathname === '/Customer/land-details'
                  ? '*'
                  : ''}
              </label>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  sx={{ top: '-6px', color: '#B2B2B2', fontSize: '0.95rem' }}
                  shrink={false}
                  id='select-city-label'
                >
                  {selectedUrbanArea === undefined &&
                    landDetails.urbanAreaId === null &&
                    'Select city/town/village'}
                </InputLabel>
                <Field name='cityTownVillage'>
                  {({ field, form, meta }) => {
                    return (
                      <Select
                        labelId='select-city-label'
                        disabled={selectedDistrict === undefined && landDetails.urbanAreaId === null}
                        sx={{
                          width: '100%',
                          my: 2,
                          display: 'block',
                          cursor: 'default',
                          height: '2.7rem',
                          marginTop: '0',
                          '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            paddingTop: '10px !important'
                          }
                        }}
                        id={'select-urban-area'}
                        value={selectedUrbanArea > 0 ? selectedUrbanArea : ''}
                        defaultValue={''}
                        onChange={(e) => {
                          setSelectedUrbanArea(e.target.value)
                          formik.setFieldTouched('cityTownVillage')
                          form.setFieldValue('cityTownVillage', e.target.value)
                        }}
                      >
                        {urbanAreas.map((urbanArea) => {
                          return (
                            <MenuItem
                              defaultValue={''}
                              value={urbanArea.id}
                              key={urbanArea.id}
                            >
                              {urbanArea.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )
                  }}
                </Field>
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* STREET */}
        {window.location.pathname === '/Customer/land-details' && (
          <Grid item xs={12} md={6}>
            <label className={classes.UploadLabelVehicle}>STREET</label>
            <Field name='street'>
              {({ field, form, meta }) => {
                return (
                  <TextField
                    placeholder='Enter street'
                    className={classes.TextFieldStyles}
                    fullWidth
                    value={landDetails.street || selectedStreet}
                    onChange={(e) => {
                      setselectedStreet(e.target.value)
                      formik.setFieldTouched('street')
                      form.setFieldValue('street', e.target.value)
                    }}
                  />
                )
              }}
            </Field>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          {/* REGISTRATION SECTION SELECTION */}
          {(!Files.registrationSectionId ||
            (Files?.registrationSectionId &&
              window.location.pathname === '/Customer/land-details')) && (
            <>
              <label className={classes.UploadLabelVehicle}>
                REGISTRATION SECTION
                {window.location.pathname === '/Customer/land-details'
                  ? '*'
                  : ''}
              </label>
              <FormControl style={{ width: '100%' }}>
                <InputLabel
                  sx={{ top: '-6px', color: '#B2B2B2', fontSize: '0.95rem' }}
                  shrink={false}
                  id='select-registration-label'
                >
                  {selectedRegsitrationSection === undefined &&
                    landDetails.registrationSectionId === null &&
                    'Select district'}
                </InputLabel>
                <Field name='registrationSectionId'>
                  {({ field, form, meta }) => {
                    return (
                      <Select
                        disabled={
                          selectedDistrict === undefined &&
                          landDetails.registrationSectionId === null
                        }
                        labelId='select-registration-label'
                        sx={{
                          width: '100%',
                          my: 2,
                          display: 'block',
                          cursor: 'default',
                          height: '2.7rem',
                          marginTop: '0',
                          '& .MuiInputBase-input.MuiOutlinedInput-input': {
                            paddingTop: '10px !important'
                          }
                        }}
                        id={'select-urban-area'}
                        value={
                          selectedRegsitrationSection !== undefined
                            ? selectedRegsitrationSection
                            : landDetails.registrationSectionId
                        }
                        defaultValue={''}
                        onChange={(e) => {
                          setselectedRegsitrationSection(e.target.value)
                          formik.setFieldTouched('registrationSectionId')
                          form.setFieldValue(
                            'registrationSectionId',
                            e.target.value
                          )
                        }}
                      >
                        {registrationSectionData.map((regisSection) => {
                          return (
                            <MenuItem
                              defaultValue={''}
                              value={regisSection.id}
                              key={regisSection.id}
                            >
                              {regisSection.name} | Block No.{' '}
                              {regisSection.blockNumber}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )
                  }}
                </Field>
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* PARCEL OR LOT NUMBER */}
        {window.location.pathname === '/Customer/land-details' && (
          <Grid item xs={12} md={6}>
            <label className={classes.UploadLabelVehicle}>
              PARCEL OR LOT NUMBER
            </label>
            <Field name='parcelOrLotNumber'>
              {({ field, form, meta }) => {
                return (
                  <TextField
                    placeholder='Enter parcel or lot number'
                    className={classes.TextFieldStyles}
                    fullWidth
                    value={landDetails.parcelOrLotNumber || selectedParcelNum || ""}
                    onChange={(e) => {
                      if (isNaN(String(e.target.value))) return
                      setselectedParcelNum(e.target.value)
                      formik.setFieldTouched('parcelOrLotNumber')
                      form.setFieldValue('parcelOrLotNumber', e.target.value)
                    }}
                  />
                )
              }}
            </Field>
          </Grid>
        )}

        <Grid item xs={12} md={6} style={{
              paddingLeft:
                window.location.pathname === '/Customer/land-details'
                  ? '16px'
                  : '0',
              paddingTop: '16px'
            }}>
          {/* LAND TITLE */}
          {(!Files.landTitle ||
            (Files?.landTitle &&
              window.location.pathname === '/Customer/land-details')) && (
            <>
              <label className={classes.UploadLabelVehicle}>
                LAND TITLE
                {window.location.pathname === '/Customer/land-details'
                  ? ''
                  : '*'}
              </label>
              <Button
                Title='Upload land title'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onlandTitleRefClick}
              />
              <Field name='landTitle'>
                {({ field, form, meta }) => {
                  return (
                    <>
                      <input
                        {...field}
                        type='File'
                        accept='application/pdf,image/*'
                        onChange={(event) => {
                          form.setFieldValue(
                            'landTitle',
                            event.currentTarget.files[0]
                          )
                        }}
                        value=''
                        style={{ display: 'none' }}
                        ref={inputLandTitleRef}
                        error={meta.touched && meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                        Only pdf documents are permitted with a maximum of 2MB
                      </Typography>
                      {meta.touched && meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: 'red' }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                      {!meta?.touched && landDetails?.landTitleName && (
                        <p className={classes.uploadFileName}>
                          {`${String(landDetails?.landTitleName).substring(
                            0,
                            25
                          )} ...`}
                          <span>
                            {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                          </span>
                        </p>
                      )}
                    </>
                  )
                }}
              </Field>
            </>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* LAND APPRAISAL */}
        {(!Files.landAppraisal ||
          (Files?.landAppraisal &&
            window.location.pathname === '/Customer/land-details')) && (
          <Grid
            item
            xs={12}
            md={6}
            style={{
              paddingLeft:
                window.location.pathname === '/Customer/land-details'
                  ? '16px'
                  : '0',
              paddingTop: '19px'
            }}
          >
            <label className={classes.UploadLabelVehicle}>
              LAND APPRAISAL
              {window.location.pathname === '/Customer/land-details' ? '' : '*'}
            </label>
            <Button
              Title='Upload land appraisal'
              variant='h7'
              className={classes.UploadFiles}
              endIcon={<AttachFileIcon />}
              onClick={onlandAppraisalRefClick}
            />
            <Field name='landAppraisal'>
              {({ field, form, meta }) => {
                return (
                  <>
                    <input
                      {...field}
                      type='File'
                      accept='application/pdf/*'
                      onChange={(event) => {
                        form.setFieldValue(
                          'landAppraisal',
                          event.currentTarget.files[0]
                        )
                      }}
                      value=''
                      style={{ display: 'none' }}
                      ref={inputLandAppraisalRef}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only pdf documents are permitted with a maximum of 2MB
                    </Typography>
                    {meta.touched && meta.error ? (
                      <p className={classes.fileText} style={{ color: 'red' }}>
                        {meta.error}
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null)
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                    {!meta?.touched && landDetails?.landAppraisalName && (
                      <p className={classes.uploadFileName}>
                        {`${String(landDetails?.landAppraisalName).substring(
                          0,
                          25
                        )} ...`}
                        <span>
                          {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                        </span>
                      </p>
                    )}
                  </>
                )
              }}
            </Field>
          </Grid>
        )}

        {(!Files.purchaseAgreement ||
          (Files?.purchaseAgreement &&
            window.location.pathname === '/Customer/land-details')) && (
          <Grid item xs={12} md={6}>
            {/* PURCHASE AGREEMENT */}
            <>
              <label
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '1.5%'
                }}
                className={classes.UploadLabelVehicle}
              >
                PURCHASE AGREEMENT
                {window.location.pathname === '/Customer/land-details'
                  ? ''
                  : '*'}
                <span onClick={toggleTooltip}>
                  <LightTooltip
                    title={`A purchase agreement is a written agreement between the buyer and the seller that outlines the terms and conditions of the purchase.`}
                    arrow
                    // placement='right'
                    open={showTooltip}
                  >
                    <img
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleTooltip()
                      }}
                      src={InfoIcon}
                      alt=''
                    />
                  </LightTooltip>
                </span>
              </label>
              <Button
                Title='Upload purchase agreement'
                variant='h7'
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onPurchaseAgreementPictureRefClick}
              />
              <Field name='purchaseAgreement'>
                {({ field, form, meta }) => {
                  return (
                    <>
                      <input
                        {...field}
                        type='File'
                        accept='application/pdf,image/*'
                        onChange={(event) => {
                          form.setFieldValue(
                            'purchaseAgreement',
                            event.currentTarget.files[0]
                          )
                        }}
                        value=''
                        style={{ display: 'none' }}
                        ref={inputPurchaseAgrementRef}
                        error={meta.touched && meta.error}
                      />
                      <Typography className={classes.UploadFileText}>
                        Only pdf documents are permitted with a maximum of 2MB
                      </Typography>
                      {meta.touched && meta.error ? (
                        <p
                          className={classes.fileText}
                          style={{ color: 'red' }}
                        >
                          {meta.error}
                        </p>
                      ) : (
                        meta?.value?.name && (
                          <p className={classes.uploadFileName}>
                            {`${String(meta.value.name).substring(0, 25)} ...`}
                            <span>
                              <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              />
                            </span>
                          </p>
                        )
                      )}
                      {!meta?.touched && landDetails?.purchaseAgreementName && (
                        <p className={classes.uploadFileName}>
                          {`${String(
                            landDetails?.purchaseAgreementName
                          ).substring(0, 25)} ...`}
                          <span>
                            {/* <ClearIcon
                                className={classes.deleteFileIcon}
                                onClick={() => {
                                  formik.setFieldValue(field.name, null)
                                }}
                              /> */}
                          </span>
                        </p>
                      )}
                    </>
                  )
                }}
              </Field>
            </>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default LandLoanData
