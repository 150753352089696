import { makeStyles } from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  LoanCalculatorHeader: {
    height: "56.52px",
    backgroundColor: theme.colors.secondary.blue,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
  },
  CalculatorHeaderText: {
    "&.MuiTypography-root": {
      fontSize: "19.4px",
      fontWeight: "500",
      color: "#ffff",
      lineHeight: "26.5px",
      letterSpacing: "0.2em",
    },
  },
  Promo: {
    width: "50%",
    "@media (max-width: 599px)": {
      width: "100%",
    }
  },
  recommend:{
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-50px !important',
    width: "50%",
    "@media (max-width: 600px)": {
      width: "100%",
      marginTop: '0 !important',
    },
  },
  RecomendLeftSec: {
    width: "50%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  RecomendImg: {
    width: "301.07px",
  },
  RecomendedInputs: {
    marginTop: "36px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  LoanFees: {
    width: "auto",
    height: "38px",
    paddingLeft: "10px",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "27.32px",
    letterSpacing: "0.1em",
    color: "#706F6F",
    borderColor: "#DADADA",
    fontFamily: theme.fonts.family.secondary,
    background: "#ffff",
    justifyContent: "unset",
    "& .MuiInputLabel-root": {
      lineHeight: "18px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  InputLable: {
    color: "#706F6F",
    fontFamily: "avenirMedium !important",
    fontWeight: 500,
    fontSize: 'small !important',
    marginBottom: 20
  },
  MonthlyInsurence: {
    width: "auto",
    height: "59.43px",
    backgroundColor: "#EAF2FB",
    fontFamily: theme.fonts.family.secondary,
    fontSize: "36px",
    fontWeight: "800",
    paddingLeft: "25px",
    lineHeight: "49px",
    letterSpacing: "0.1em",
    color: "#706F6F",
    justifyContent: "unset",
    "& .MuiTypography-root": {
      fontFamily: theme.fonts.family.tertiary,
      fontSize: "35px",
      lineHeight: "49px",
      letterSpacing: "0.02em",
      fontWeight: "800",
    },
  },
  EnterPromoCode: {
    height: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: theme.colors.secondary.blue,
    cursor: "pointer",
    margin: "11px 0 35px 0",
    textAlign: "center",
    color: "white",
    "& .MuiTypography-root": {
      fontFamily: theme.fonts.family.tertiary,
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.02em",
      fontWeight: "500",
    },
  },
  Amount: {
    "&.MuiInputBase-input.Mui-disabled": {
      color: "red",
    },
  },
  Terms: {
    "& .MuiInputBase-input": {
      color: "black",
    },
  },
  RecomendRightSec: {
    width: "50%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  BtnTermsAndConditions: {
    "&.css-sghohy-MuiButtonBase-root-MuiButton-root": {
      width: "100%",
      height: "36.03px",
      backgroundColor: "#56AF31",
    },
    "& .css-ahj2mt-MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
    },
    "@media (max-width: 400px)": {
      width: "100%",
    },
  },
  TermsAndConditions: {
    width: '50%',
    "@media (max-width: 400px)": {
      width: "100%",
    },
  },
  PromoCode: {
    "& .MuiInputBase-root .MuiInputBase-input": {
      height: "65px",
      padding: "0px 25px",
      fontFamily: theme.fonts.family.secondary,
      fontSize: "25px",
      fontWeight: "500",
      lineHeight: "34px",
      letterSpacing: "0.1em",
      color: "#706F6F",
    },
    "& .MuiInputLabel-root": {
      lineHeight: "18px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.secondary,
    },

  },
  Links: {
    textDecoration: "none",
  },
  AmountInput: {
    // "& .MuiInputBase-input": {
    //   textAlign: "center",
    // },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  BlankInput: {
    "& .MuiInputBase-input": {
      marginBottom: "16.8px",
      border: "1px solid #DADADA",
      color: "black",
      outLine: "none",
      fontSize: "18px",
      fontFamily: theme.fonts.family.tertiary,
      color: "#7F7F80",
      fontWeight: "600",
      cursor: 'default'
    }
  },
  Alert: {
    backgroundColor: "#F4EBFE",
     fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: theme.fonts.family.secondary,
  }
}))
