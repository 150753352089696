import { makeStyles } from "@material-ui/core";
import { Height } from "@mui/icons-material";

export const useStyles = makeStyles((theme) => ({
  //* crearemos el diseNos de la caja tooltip que pareza comouna tarjeta de chat con un borde redondeado y una de las esquinas apuntandoal infoicon
  tooltip: {
    position: "relative", // Agrega posición relativa para el seudoelemento
    backgroundColor: "#fff !important",
    color: "#275DA6 !important",
    maxWidth: "216px",
    height: "auto",
    left: '30px',
    top: '-10px',
    textTransform: "none !important",
    fontSize: "14px !important",
    boxShadow: theme.shadows[1],
    border: "1px solid #275DA6 !important",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    "& .MuiTooltip-arrow": {
      display: "none", // Oculta la flecha por defecto
    },
    // Agrega un triángulo pseudo-elemento, por ahora comentado
/*     "&::before": {
      content: '""',
      position: "absolute",
      top: "-10px", // Ajusta la posición vertical
      //left: "90%", // Alinea el triángulo en el centro
      transform: "translateX(-50%)", // Centra horizontalmente
      width: 0,
      height: 0,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #275DA6", // Ajusta el color y tamaño del triángulo
    }, */
  },
}));

