import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { Stack, Box } from '@mui/system'
import { Input, Currency } from '../../components/'
import { useStyles } from './LoanDetailsStyle'
import { useDispatch, useSelector } from 'react-redux'
import { FormikProvider, useFormik, Field } from 'formik'
import LoanOptions from '../loan-options/'
import './styles.css'
import { amountValidation, termValidation } from './validationUtils'
import { setAmount, setOtherValules, setTerms } from '../../context'
import { formatter } from '../../../../../../Utils'
import { setLoanOptions } from '../../context'

const validationSchema = Yup.object().shape({
  otherAmount: amountValidation,
  otherTerms: termValidation
})

const LoanDetails = ({}) => {
  const dispatch = useDispatch()
  const {
    loanOptions,
    loanAmounts,
    loanTerms,
    otherFields,
    requestedAmount,
    requestedTerms
  } = useSelector((state) => state.cashLoanCollateral)
  // const { requestedAmount, requestedTerms } = useSelector((state) => state.LoanRequestReducer)
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      otherAmount: loanOptions.amount ?? 0,
      otherTerms: loanOptions.terms ?? 0,
      requestedAmount,
      requestedTerms
    },
    validationSchema: validationSchema
  })

  useEffect(() => {
    formik.setFieldValue('otherAmount', loanOptions.amount);
    formik.setFieldValue('otherTerms', loanOptions.terms);

  }, [loanOptions])

  const onChangeInputOtherAmount = (e, value) => {
    let inputValue = value;
    // console.log("value", value)
    if (!value) {
      inputValue = 0;
    }

    const amount = parseFloat(inputValue);
    amount >= 1_000 && dispatch(setAmount(amount));
    formik.setFieldValue('otherAmount', amount);
    dispatch(setOtherValules({ option: 'otherAmount', value: amount }));
  }

  const onChangeInputOtheTerm = (e) => {
    const terms = e.target.value ? parseInt(e.target.value) : '';
    terms >= 12 && dispatch(setTerms(terms));
    formik.setFieldValue('otherTerms', terms);
    dispatch(setOtherValules({option: 'otherTerms',value: terms}));
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Stack direction='column' className={classes.Input}>
          <LoanOptions
            inputValue={
              loanOptions.amount
                ? formatter.format(loanOptions.amount)
                : loanOptions.requestedAmount 
                    ? formatter.format(loanOptions.requestedAmount)
                    : "BZD"
            }
            optionsList={loanAmounts}
            title='LOAN AMOUNT (Select one)'
          />
          {otherFields.amount && (
            <Box>
              <Field name='otherAmount'>
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                    meta.value !== ''}
                    <Currency
                      {...field}
                      value={meta.value}
                      onChange={(e, value) => {
                        onChangeInputOtherAmount(e, value)
                      }}
                      outputFormat='number'
                      error={meta.value >= 1000 ? undefined : meta.error}
                      placeholder={'the amount must be greater than 1,000'}
                    />
                  </>

                )}
              </Field>
            </Box>
          )}
        </Stack>

        <Stack direction='column' className={classes.Input}>
          <LoanOptions
            inputValue={
              loanOptions.terms ? loanOptions.terms : loanOptions.requestedTerms
            }
            optionsList={loanTerms}
            title='LOAN TERM (select one)'
          />
          {otherFields.terms && (
            <Box>
              <Field name='otherTerms'>
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                      meta.value !== ''}
                    <Input
                      {...field}
                      type='number'
                      value={meta.value}
                      onChange={(e) => {
                        onChangeInputOtheTerm(e)
                      }}
                      className={classes.BlankInput}
                      placeholder='the term must be greater than 12 months'
                      error={meta.error}
                    />
                  </>
                )}
              </Field>
            </Box>
          )}
        </Stack>
      </FormikProvider>
    </>
  )
}

export default LoanDetails
