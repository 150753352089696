const Actions = {
  LETSMEETFORM: "LETSMEETFORM",
  EROOR: "EROOR",
  USEREXSITS: "USEREXSITS",
  LOADING: "LOADING",
  SSN: "SSN",
  SSNEXISTS: "SSNEXISTS",
  SUCCESS: "SUCCESS",
  OTPVALIDATION: "OTPVALIDATION",
  PROMOCODE: "PROMOCODE",
  PROMOCODEINVALID: 'PROMOCODEINVALID',
  LOANREQUEST: "LOANREQUEST",
  SETMONTHLYINSTALLMENTWITHPROMOCODE: "SETMONTHLYINSTALLMENTWITHPROMOCODE",
  SETISVALIDPROMOCODE: "SETISVALIDPROMOCODE",
  SETSHOWINVALIDPROMOCODEMODAL: "SETISVALIDPROMOCODE",
  SETLOADNREQUEST: "SETLOADNREQUEST",
  SETTERMSTDETAILS: "SETTERMSTDETAILS",
  SETAMOUNTDETAILS: "SETAMOUNTDETAILS",
  OTPERROR: "OTPERROR",
  SNACKBARMESSAGES: "SNACKBARMESSAGES",
  ADDRESSVALIDATE: "ADDRESSVALIDATE",
  OTPSUCCESS: "OTPSUCCESS",
  PROOF: "PROOF",
  CLEARSTATE : "CLEARSTATE",
  CLEAR_STATE_REFERENCE_NUMBER: "CLEAR_STATE_REFERENCE_NUMBER",
  CLEARLOANREQUESTSTATE : "CLEARLOANREQUESTSTATE",
  SCHEDULELOANDREQUEST: "SCHEDULELOANDREQUEST",
  ACCOUNTMATCHINGSTATUS: "ACCOUNTMATCHINGSTATUS",
  GETMESSAGES: "GetMessages",
  USERMESSAGES: "USERMESSAGES",
  SCHEDULE: "SCHEDULE",
  MAKESCHEDULE: "MAKESCHEDULE",
  SHOWSCHEDULETHANKYOU: "SHOWSCHEDULETHANKYOU",
  REFERENCEVALIDATION: "REFERENCEVALIDATION",
  REFERENCESUCCESS: "REFERENCESUCCESS",
  REFERENCESTATUS: "REFERENCESTATUS",
  REFERENCESTATUSERROR: "REFERENCESTATUSERROR",
  REFERENCECODE: "REFERENCECODE",
  REFERENCEFINALIZED: "REFERENCEFINALIZED",
  REFERENCEDECLINED: "REFERENCEDECLINED",
  REFERENCESERROR: "REFERENCESERROR",
  REFERENCELINK: "REFERENCELINK",
  OTPEMAIL: "OTPEMAIL",
  OTPEMAILERROR: "OTPEMAILERROR",
  EMAILRESPONSE: "EMAILRESPONSE",
  EMAILSHOWMODALERROR: "EMAILSHOWMODALERROR",
  EMAILSHOWMODALRETRY: "EMAILSHOWMODALRETRY",
  STATUS :"STATUS",
  GETSTATUSES: 'GETSTATUSES',
  INITIAL_GETSTATUSES: 'INITIAL_GETSTATUSES',
  GETSTATUS_CURRENT: 'GETSTATUS_CURRENT',
  GETSTATUSES_COMPLETED: 'GETSTATUSES_COMPLETED',
  UPLOADDOCUMENTS: 'UPLOADDOCUMENTS',
  AGElIMIT: "AGElIMIT",
  GETACCOUNTTYPES: 'GETACCOUNTYPES',
  POSTBBANK: 'POSTBBANK',
  SETLEGALFEESTYPES: 'SETLEGALFEESTYPES',
  SETTOTALPREQUALIFIEDLOANVALUE: 'SETTOTALPREQUALIFIEDLOANVALUE'
}

export default Actions
