import { Card } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Image from '../../atoms/Image/Image'
import Title from '../../atoms/Title/Title'
import React, { useState } from 'react'
// import { ButtonTypeLoan } from '../../../../../components'
import { Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import InfoActiveIcon from '../../../../assets/Icons/Info-Active.png'
import InfoInactiveIcon from '../../../../assets/Icons/Info-Inactive.png'
import TooltipIcon from '../../../../assets/Images/info-circle.png'
import { useStyles } from './CardItemStyle'

const CardItem = ({ LoanList, isActive, handleActive }) => {
  const [activeOption, setactiveOption] = useState(false) // Estado para el color de fondo
  const [showTooltip, setShowTooltip] = useState(false) // Estado para mostrar u ocultar el Tooltip
  const [onHoverCard, setonHoverCard] = useState(false)
  const classes = useStyles()

  const handleClick = (e) => {
    e.preventDefault()
    // Alterna entre dos colores de fondo en cada clic
    console.log(isActive)
    setactiveOption(!isActive)
  }

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const tooltipMessage = (() => {
    switch (LoanList.loanType) {
      case 'refinancing':
        return 'Refinancing your loan means that you will borrow funds to pay off your existing debt and obtain additional funds to cover for your purchases. New loan agreements are signed for the consolidated amount.';
      case 'secure-unsecure':
        return 'This loan can be unsecured or cash secured. Unsecured loans require no collateral, while cash-secured loans use cash as a guarantee for at least 80% of the loan amount.';
      default:
        return `This loan type is a secured loan. Secured loans are loans in which you pledge some asset as collateral for the loan. This can be done in the form of ${LoanList.warrantyType === 'vehicle' ? 'vehicle.' : 'land.'}`;
    }
  })();

  return (
    <Card
      onClick={handleActive}
      onMouseEnter={() => setonHoverCard(true)}
      onMouseLeave={() => setonHoverCard(false)}
      sx={{
        backgroundImage: onHoverCard
          ? ''
          : `linear-gradient(${LoanList.gradient}), url(${LoanList.bgImage})`,
        background: onHoverCard ? LoanList.bgColor : '',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition: 'background-color 0.25s ease-in-out'
      }}
      classes={{ root: classes.mainCardStyle }} 
    >
      <div onClick={toggleTooltip} style={{ cursor: 'pointer' }}>
        <Tooltip
          open={showTooltip}
          title={tooltipMessage}
          placement='bottom-end'
          classes={{ tooltip: classes.tooltip }} // Clase personalizada para el tooltip
          PopperProps={{
            disablePortal: false // Evitar problemas de posicionamiento
          }}
        >
          {LoanList.InfoIcon && (
            <div
              style={{
                width: '10px',
                position: 'absolute',
                bottom: '7px',
                right: '18px'
              }}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={(e) => {
                e.stopPropagation()
                toggleTooltip()
              }}
            >
              <Image
                className='info-icon'
                src={TooltipIcon}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        </Tooltip>
      </div>
      <CardContent
        style={{
          height: '112px',
          display: 'flex',
          // flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          cursor: 'pointer'
          // marginTop: LoanList.InfoIcon ? '0px' : '32px'
        }}
      >
        {/* <Image
          src={isActive ? LoanList.ActiveImg : LoanList.InactiveImg}
          height={LoanList.Height}
          width={LoanList.Width}
        /> */}
        <Title
          Text={LoanList.PrincipalTitle}
          color={'white'}
          // margin='16px'
          fontWeight={'400'}
          fontSize={'20px'}
          aling={'center'}
        />
        {/* <Title
          Text={LoanList.SecondTitel}
          color={!isActive ? '#53AF32' : 'white'}
          margin='0px'
          fontWeight={'700'}
          size={'14px'}
          aling={'center'}
        /> */}
      </CardContent>
    </Card>
  )
}

export default CardItem
